import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonSpinner
} from '@ionic/react';
import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import {AuthStore} from '../stores/AuthStore';
import {RouteComponentProps, useHistory} from "react-router";
import Timer from "../components/Timer";

interface CustomProps extends RouteComponentProps {
    authStore: AuthStore;
}

const Auth: React.FC<CustomProps> = ({authStore}) => {
    const history = useHistory();

    useEffect(() => {
        if (authStore.isAuth) history.replace('/shift/start');
    }, [authStore.isAuth, history]);

    const handleInputPhone = (e: React.FormEvent<HTMLIonInputElement>): void => authStore.setUserPhone(e.currentTarget.value || '');
    const handleInputCode = (e: React.FormEvent<HTMLIonInputElement>): void => authStore.setCode(e.currentTarget.value || '');
    const handleSendPhone = (): void => authStore.sendPhone();
    const handleSendCode = (): void => authStore.sendCode();

    return (
        <IonPage>
            <IonContent>
                <div className="auth-wrap">
                    <IonImg className="auth-logo" src="/assets/logo_beegudi_color.svg"/>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>Вход в приложение</IonCardTitle>
                        </IonCardHeader>
                        {!authStore.getCode && (
                            <IonCardContent>
                                <IonItem className="start-0">
                                    <IonLabel>+7</IonLabel>
                                    <IonInput onInput={handleInputPhone}
                                              name="phone"
                                              placeholder="Номер телефона"
                                              type="tel"
                                              inputMode="tel"
                                              value={authStore.userPhone}
                                              maxlength={10}
                                              disabled={authStore.isSync || authStore.isAuth}/>
                                    {authStore.isSync && (<IonSpinner slot="end"/>)}
                                </IonItem>
                                <IonButton className="auth-btn btn-primary"
                                           expand="block"
                                           disabled={!authStore.checkValidUserPhone || authStore.isSync || authStore.isAuth}
                                           onClick={handleSendPhone}>
                                    Войти
                                </IonButton>
                            </IonCardContent>
                        )}
                        {authStore.getCode && (
                            <IonCardContent>
                                <IonItem className="start-0 mt-n16">
                                    <IonLabel className="code-label" position="floating">Введите код из SMS</IonLabel>
                                    <IonInput onInput={handleInputCode}
                                              name="code"
                                              type="tel"
                                              inputMode="tel"
                                              value={authStore.code}
                                              maxlength={4}
                                              disabled={authStore.isSync || authStore.isAuth}/>
                                    <Timer authStore={authStore}/>
                                    {authStore.isSync && (<IonSpinner className="code-timer" slot="end"/>)}
                                </IonItem>
                                <IonButton className="auth-btn btn-primary"
                                           expand="block"
                                           disabled={authStore.code.length < 4 || authStore.isSync || authStore.isAuth}
                                           onClick={handleSendCode}>
                                    Отправить
                                </IonButton>
                            </IonCardContent>
                        )}
                        {authStore.authError && (
                            <IonCardContent className="pt-0">
                                <div className="error">{authStore.authError}</div>
                            </IonCardContent>
                        )}
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default inject('authStore')(observer(Auth));
