import { AppUpdate } from '@ionic-native/app-update';
import { persist } from 'mobx-persist';
import { action, computed, observable, runInAction } from 'mobx';
import { ErrorResponse, ShiftRequests, RoutePayCheckStatusResponse } from '../api/Requests';
import { RefresherEventDetail } from '@ionic/core';
import * as Sentry from '@sentry/browser';
import { API_ROOT, PATH_UPDATE_XML, PATH_UPDATE_XML_DEV, TEST_PHONE_NUMBER } from '../config';
import { authStore } from './AuthStore';

export interface ShiftRoute {
    readonly accept_payment: boolean;
    readonly address: string;
    readonly address_coordinates: {
        readonly lat: string;
        readonly lon: string;
    };
    readonly address_fail: boolean;
    readonly address_short: string;
    readonly contacts: ShiftRouteContacts;
    finished: boolean;
    readonly legal_name: string;
    readonly old_route: boolean;
    orders: ShiftRouteOrder[];
    payments: ShiftRoutePayment[];
    readonly route_id: number;
    readonly tasks: ShiftRouteTask[];
    readonly total_cost: number;
}

export interface ShiftRouteTask {
    finished: boolean;
    readonly task_id: number;
    readonly task_name: string;
}

export interface ShiftRouteOrder {
    disagreement: boolean;
    readonly order_id: number;
    readonly order_name: string;
    readonly products: ShiftRouteProduct[];
    expanded?: boolean;
}

export interface ShiftRouteProduct {
    readonly product_count: number;
    readonly product_id: string;
    readonly product_name: string;
    readonly product_number: string;
    readonly product_price: number;
    disagreement_cause: string;
    disagreement_count: number;
    disagreement_checked: boolean;
}

export interface ShiftRoutePayment {
    cost: string | number;
    readonly id: number;
    paid: boolean;
    readonly pay_name: string;
    readonly postpay: boolean;
    checked: boolean;
}

export interface ShiftRouteContacts {
    readonly client: {
        readonly phone?: string | null;
        readonly whatsapp?: string | null;
    },
    readonly manager: {
        readonly phone?: string | null;
        readonly whatsapp?: string | null;
    }
}

interface ShiftRouteState {
    [key: number]: 'started' | 'arrived';
}

export interface ShiftCollection {
    readonly balance_info: ShiftCollectionBalance;
    readonly collection_balance: number;
    readonly collection_id: number;
    readonly collection_name: string;
    manual_balance: number;
    photo: any;
}

export interface ShiftCard {
    card_number: string,
    credit: string,
}

interface ShiftCollectionBalance {
    readonly balance_begin: number;
    readonly balance_ending: number;
    readonly history: ShiftCollectionBalanceHistory[];
}

interface ShiftCollectionBalanceHistory {
    readonly cost: number;
    readonly time: string;
    readonly title: string;
    readonly type: 'fundraising' | 'expense';
}

export interface ShiftTask {
    readonly task_id: number;
    readonly task_name: string;
    finished: boolean;
}

export interface Disagreement {
    readonly description: string;
    readonly key: string;
}

export interface PaymentData {
    cost: string;
    payment_id: number;
    pay_method: ('cash' | 'qr');
}

interface RoutePayError extends ErrorResponse {
    data: {
        success: boolean;
        message?: string;
        orders: number[];
    }
}

interface OrderDisagreementError extends ErrorResponse {
    data: {
        success: boolean;
        message?: string;
        total_amount: number;
    }
}

interface AppUpdateResponse {
    code: number;
    msg: string;
}

export class ShiftStore {
    @persist('list') @observable shiftRoutes: ShiftRoute[] = [];
    @persist('list') @observable disagreementList: Disagreement[] = [];
    @persist('object') @observable shiftStart: boolean = false;
    @persist('object') @observable shiftId: number | null = null;
    @persist('object') @observable shiftDate: string = '';
    @persist('object') @observable shiftRouteState: ShiftRouteState = {};
    @observable shiftTasks: ShiftTask[] = [];
    @observable shiftCollections: ShiftCollection[] = [];
    @observable shiftCards: ShiftCard[] = [];
    @observable shiftCloseSuccess: boolean = false;
    @observable shiftOrderDisagreementId: number | null = null;
    @observable storeInit: boolean | null = null;
    @observable isSync: boolean = false;
    @observable error: string[] = [];
    @observable authError: string | null = null;
    @observable updateAvailable: boolean = false;

    get welcomeText(): string {
        let welcome = 'Доброе утро';
        const curHours = new Date().getHours();
        if (curHours >= 0 && curHours < 5) welcome = 'Доброй ночи';
        if (curHours >= 12 && curHours < 19) welcome = 'Добрый день';
        if (curHours >= 19 && curHours <= 23) welcome = 'Добрый вечер';
        return welcome;
    }

    @computed get shiftDateFormat(): string {
        if (!this.shiftDate) return 'Сегодня';
        const curDate: Date = new Date();
        let shiftDate: string[] = this.shiftDate.split(' ')[0].split('-');
        let curMonth: number = curDate.getMonth() + 1;
        let curDay: number = curDate.getDate();
        if (curMonth === parseInt(shiftDate[1], 10)) {
            switch (curDay - parseInt(shiftDate[2], 10)) {
                case 0:
                    return 'Сегодня';
                case 1:
                    return 'Вчера';
                case 2:
                    return 'Позавчера';
            }
        }
        return `${shiftDate[2]}-${shiftDate[1]}-${shiftDate[0]}`;
    };

    priceFormat(price: number | string): string {
        return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
    };

    cardNumberFormat(number: string): string {
        return `**** **** **** ${number.substr(-4)}`;
    };

    getRouteById(id: string): ShiftRoute | null {
        for (let i = 0; i < this.shiftRoutes.length; i++) {
            if (this.shiftRoutes[i].route_id === parseInt(id, 10)) return this.shiftRoutes[i];
        }
        return null;
    };

    cleanPhoneNumber(phone: string): string {
        phone = phone.replace(/\D/g, '');
        if (phone.length === 10) phone = '+7' + phone;
        else phone = '+7' + phone.slice(1, 11);
        return phone;
    };

    checkFinishedTasks(tasks: ShiftRouteTask[]): boolean {
        for (let i = 0; i < tasks.length; i++) {
            if (!tasks[i].finished) return false;
        }
        return true;
    };

    additionFloat(a: string | number, b: string | number): string {
        a = a.toString();
        b = b.toString();
        if (parseFloat(a) === 0) return b;
        if (parseFloat(b) === 0) return a;
        let _a: string[] = a.split('.');
        let _b: string[] = b.split('.');
        let integer: number = parseInt(_a[0], 10) + parseInt(_b[0], 10);
        if (_a.length === 1 && _b.length === 1) return integer.toString();
        if (_a.length === 1) _a[1] = '0';
        if (_b.length === 1) _b[1] = '0';
        _a[1] += '0';
        _b[1] += '0';
        let fraction: number | string = parseInt(_a[1].substr(0, 2), 10) + parseInt(_b[1].substr(0, 2), 10);
        if (fraction >= 100) {
            integer++;
            fraction -= 100;
        }
        if (fraction.toString().length < 2) fraction = `0${fraction}`;
        return `${integer}.${fraction}`;
    };

    subtractionFloat(a: string | number, b: string | number): string {
        a = a.toString();
        b = b.toString();
        if (parseFloat(a) === 0) return `-${b}`;
        if (parseFloat(b) === 0) return a;
        let _a: string[] = a.split('.');
        let _b: string[] = b.split('.');
        let integer: number | string;
        let isInteger: boolean = _a.length === 1 && _b.length === 1;
        let isSimpleSubtraction: boolean = parseFloat(a) >= parseFloat(b);
        if (isSimpleSubtraction) {
            integer = parseInt(_a[0], 10) - parseInt(_b[0], 10);
            if (isInteger) return integer.toString();
        } else {
            integer = parseInt(_b[0], 10) - parseInt(_a[0], 10);
            if (isInteger) return `-${integer}`;
        }
        if (_a.length === 1) _a[1] = '0';
        if (_b.length === 1) _b[1] = '0';
        _a[1] += '0';
        _b[1] += '0';
        let fraction: number | string;
        if (isSimpleSubtraction) {
            fraction = parseInt(_a[1].substr(0, 2), 10) - parseInt(_b[1].substr(0, 2), 10);
        } else {
            fraction = parseInt(_b[1].substr(0, 2), 10) - parseInt(_a[1].substr(0, 2), 10);
        }
        if (fraction < 0) {
            if (integer > 0) {
                integer--;
                fraction = 100 + fraction;
            } else {
                if (integer === 0) integer = `-${integer}`;
                fraction *= -1;
            }
        }
        if (!isSimpleSubtraction && integer.toString()[0] !== '-') integer = `-${integer}`;
        if (fraction.toString().length < 2) fraction = `0${fraction}`;
        return `${integer}.${fraction}`;
    };

    checkValidCost(cost: string): boolean {
        return !!cost.match(/^(\d)+(\.?\d{1,2})?$/g);
    };

    paymentDataMatching(payments: ShiftRoutePayment[], payMethod: ('cash' | 'qr' | 'mixed'), sum: string[]): PaymentData[] {
        let modulo: string = sum[0];
        let moduloQR: string = sum[1];
        let paymentsRequired: PaymentData[] = [];
        let paymentsPostpay: PaymentData[] = [];
        let paymentsOverflow: PaymentData[] = [];
        for (let i = 0; i < payments.length; i++) {
            if (payments[i].postpay) {
                paymentsPostpay.push({
                    payment_id: payments[i].id,
                    cost: payments[i].cost.toString(),
                    pay_method: 'cash'
                });
            } else {
                paymentsRequired.push({
                    payment_id: payments[i].id,
                    cost: payments[i].cost.toString(),
                    pay_method: 'cash'
                });
            }
        }
        if (payMethod === 'cash' || payMethod === 'qr') {
            for (let i = 0; i < paymentsRequired.length; i++) {
                paymentsRequired[i].pay_method = payMethod;
                let cost = paymentsRequired[i].cost;
                if (i === paymentsRequired.length - 1 && !paymentsPostpay.length) cost = modulo;
                else modulo = this.subtractionFloat(modulo, cost);
                paymentsRequired[i].cost = cost;
            }
            for (let i = 0; i < paymentsPostpay.length; i++) {
                paymentsPostpay[i].pay_method = payMethod;
                if (parseFloat(modulo) <= 0) {
                    paymentsPostpay[i].cost = '0';
                    continue;
                }
                let cost = modulo;
                modulo = this.subtractionFloat(modulo, paymentsPostpay[i].cost);
                if (parseFloat(modulo) <= 0) modulo = '0';
                else {
                    if (i !== paymentsPostpay.length - 1) cost = paymentsPostpay[i].cost;
                }
                paymentsPostpay[i].cost = cost;
            }
        } else {
            for (let i = 0; i < paymentsRequired.length; i++) {
                let method: ('cash' | 'qr') = 'cash';
                let cost = modulo;
                if (parseFloat(modulo) <= 0) {
                    cost = moduloQR;
                    method = 'qr';
                }
                paymentsRequired[i].pay_method = method;
                if (method === 'cash') {
                    modulo = this.subtractionFloat(modulo, paymentsRequired[i].cost);
                    if (parseFloat(modulo) < 0) {
                        if (i !== paymentsRequired.length - 1 || paymentsPostpay.length > 0) {
                            moduloQR = this.subtractionFloat(moduloQR, modulo.substr(1));
                            paymentsOverflow.push({
                                payment_id: paymentsRequired[i].payment_id,
                                pay_method: 'qr',
                                cost: modulo.substr(1)
                            });
                        }
                        modulo = '0';
                    } else {
                        if (i !== paymentsRequired.length - 1 || paymentsPostpay.length > 0) cost = paymentsRequired[i].cost;
                    }
                    if (i === paymentsRequired.length - 1 && parseFloat(moduloQR) > 0 && !paymentsPostpay.length) {
                        paymentsOverflow.push({
                            payment_id: paymentsRequired[i].payment_id,
                            pay_method: 'qr',
                            cost: moduloQR
                        });
                    }
                } else {
                    moduloQR = this.subtractionFloat(moduloQR, paymentsRequired[i].cost);
                    if (parseFloat(moduloQR) <= 0) moduloQR = '0';
                    else {
                        if (i !== paymentsRequired.length - 1 || paymentsPostpay.length > 0) cost = paymentsRequired[i].cost;
                    }
                }
                paymentsRequired[i].cost = cost;
            }
            for (let i = 0; i < paymentsPostpay.length; i++) {
                let method: ('cash' | 'qr') = 'cash';
                let cost = modulo;
                if (parseFloat(modulo) <= 0) {
                    cost = moduloQR;
                    method = 'qr';
                }
                paymentsPostpay[i].pay_method = method;
                if (parseFloat(modulo) <= 0 && parseFloat(moduloQR) <= 0) {
                    paymentsPostpay[i].cost = '0';
                    continue;
                }
                if (method === 'cash') {
                    modulo = this.subtractionFloat(modulo, paymentsPostpay[i].cost);
                    if (parseFloat(modulo) < 0) {
                        if (i !== paymentsPostpay.length - 1) {
                            if (parseFloat(moduloQR) < parseFloat(modulo.substr(1))) {
                                paymentsOverflow.push({
                                    payment_id: paymentsPostpay[i].payment_id,
                                    pay_method: 'qr',
                                    cost: moduloQR
                                });
                                moduloQR = '0';
                            } else {
                                moduloQR = this.subtractionFloat(moduloQR, modulo.substr(1));
                                paymentsOverflow.push({
                                    payment_id: paymentsPostpay[i].payment_id,
                                    pay_method: 'qr',
                                    cost: modulo.substr(1)
                                });
                            }
                        }
                        modulo = '0';
                    } else {
                        if (i !== paymentsPostpay.length - 1) cost = paymentsPostpay[i].cost;
                    }
                    if (i === paymentsPostpay.length - 1 && parseFloat(moduloQR) > 0) {
                        paymentsOverflow.push({
                            payment_id: paymentsPostpay[i].payment_id,
                            pay_method: 'qr',
                            cost: moduloQR
                        });
                    }
                } else {
                    moduloQR = this.subtractionFloat(moduloQR, paymentsPostpay[i].cost);
                    if (parseFloat(moduloQR) <= 0) moduloQR = '0';
                    else {
                        if (i !== paymentsPostpay.length - 1) cost = paymentsPostpay[i].cost;
                    }
                }
                paymentsPostpay[i].cost = cost;
            }
        }
        return paymentsRequired.concat(paymentsPostpay, paymentsOverflow);
    }

    collectionBalanceRound(balance: number): string {
        let integer: string = balance.toString().split('.')[0];
        if (integer.length < 3) return '0';
        return integer.slice(0, -2) + '00';
    }

    @action updateApp(checkUpdateOnly: boolean = false): void {
        this.updateAvailable = false;
        const path = (API_ROOT.indexOf('parfum2') !== -1 && authStore.userPhone === TEST_PHONE_NUMBER) ? PATH_UPDATE_XML_DEV : PATH_UPDATE_XML;

        AppUpdate.checkAppUpdate(path, {checkUpdateOnly: checkUpdateOnly} as any).then((e: AppUpdateResponse) => {
            runInAction(() => {
                if (checkUpdateOnly) {
                    if (e.code === 201) return this.updateAvailable = true;
                    if (e.code >= 300) return Sentry.captureMessage(`[Update ${e.code}] ${e.msg}`, Sentry.Severity.Error);
                } else {
                    if (e.code === 100) return Sentry.captureMessage(`[Update ${e.code}] ${e.msg}`, Sentry.Severity.Warning);
                    if (e.code === 201) return Sentry.captureMessage(`[Update ${e.code}] ${e.msg}`, Sentry.Severity.Info);
                    if (e.code === 202) {
                        Sentry.captureMessage(`[Update ${e.code}] ${e.msg}`, Sentry.Severity.Info);
                        return shiftStore.error.push('У вас последняя версия приложения');
                    }
                    if (e.code >= 300) {
                        Sentry.captureMessage(`[Update ${e.code}] ${e.msg}`, Sentry.Severity.Error);
                        return shiftStore.error.push('Произошла неизвестная ошибка');
                    }
                }
            });
        }).catch(e => {
            console.error(e);
            Sentry.captureMessage('[UpdateError]', Sentry.Severity.Error);
        });
    }

    @action setInit(): void {
        this.storeInit = true;
        if (this.shiftId) {
            Sentry.setExtras({
                'Shift ID': this.shiftId,
                'Shift Date': this.shiftDate
            });
        }
    };

    @action clearError(): void {
        this.error = [];
        this.authError = null;
    };

    @action setShiftStart(state: boolean): void {
        this.shiftStart = state;
    };

    @action setShiftEnd(): void {
        this.shiftStart = false;
        this.shiftRoutes = [];
        this.shiftId = null;
        this.shiftDate = '';
        this.disagreementList = [];
        this.shiftTasks = [];
        this.shiftCollections = [];
        this.shiftCloseSuccess = false;
        this.shiftOrderDisagreementId = null;
        this.isSync = false;
        this.error = [];
        this.authError = null;
        this.updateAvailable = false;
    };

    @action resetShift(): void {
        this.shiftRouteState = {};
        this.setShiftEnd();
        Sentry.setExtras({
            'Shift ID': null,
            'Shift Date': null
        });
    };


    @action taskClose(task: ShiftRouteTask): void {
        if (this.isSync) return;
        if (!task?.task_id) {
            this.error.push('Отсутствует ID задачи, пожалуйста обновите смену или перезапустите приложение');
            Sentry.captureMessage('[RequestError] No task_id in taskClose', Sentry.Severity.Error);
            return;
        }
        this.isSync = true;
        ShiftRequests.taskClose({
            task_id: task.task_id
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                task.finished = true;
            });
        }).catch((error: ErrorResponse) => this.errorHandler(error, 'taskClose'));
    };

    @action routeStart(routeId: number): void {
        if (this.isSync) return;
        if (!routeId) {
            this.error.push('Отсутствует ID маршрута, пожалуйста обновите смену или перезапустите приложение');
            Sentry.captureMessage('[RequestError] No route_id in routeStart', Sentry.Severity.Error);
            return;
        }
        this.isSync = true;
        ShiftRequests.routeStart({
            route_id: routeId
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                this.shiftRouteState[routeId] = 'started';
            });
        }).catch((error: ErrorResponse) => {
            if (error.status === 421) {
                runInAction(() => {
                    this.shiftRouteState[routeId] = 'started';
                });
            }
            this.errorHandler(error, 'routeStart');
        });
    };

    @action routeArrived(routeId: number): void {
        if (this.isSync) return;
        if (!routeId) {
            this.error.push('Отсутствует ID маршрута, пожалуйста обновите смену или перезапустите приложение');
            Sentry.captureMessage('[RequestError] No route_id in routeArrived', Sentry.Severity.Error);
            return;
        }
        this.isSync = true;
        ShiftRequests.routeArrived({
            route_id: routeId
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                this.shiftRouteState[routeId] = 'arrived';
            });
        }).catch((error: ErrorResponse) => {
            if (error.status === 421) {
                runInAction(() => {
                    this.shiftRouteState[routeId] = 'arrived';
                });
            }
            this.errorHandler(error, 'routeArrived');
        });
    };

    @action routeEnd(route: ShiftRoute): void {
        if (this.isSync) return;
        if (!route?.route_id) {
            this.error.push('Отсутствует ID маршрута, пожалуйста обновите смену или перезапустите приложение');
            Sentry.captureMessage('[RequestError] No route_id in routeEnd', Sentry.Severity.Error);
            return;
        }
        this.isSync = true;
        ShiftRequests.routeEnd({
            route_id: route.route_id,
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                route.finished = true;
                delete this.shiftRouteState[route.route_id];
            });
        }).catch((error: ErrorResponse) => this.errorHandler(error, 'routeEnd'));
    };

    @action routePay(
      routeId: number,
      payments: ShiftRoutePayment[],
      payMethod: 'cash' | 'qr' | 'mixed',
      sum: [string, string],
    ): Promise<void> {
        if (this.isSync) return Promise.reject();
        if (payMethod === 'qr') return Promise.resolve();
        if (sum?.length && sum[0] === sum[1] && parseFloat(sum[0]) === 0) {
            this.error.push('Оплата невозможна, итоговая сумма равна нулю');
            Sentry.captureMessage('[RequestError] Sum is 0 in routePay', Sentry.Severity.Error);
            return Promise.reject();
        }
        if (!routeId || !payments?.length || !payMethod || !sum?.length) {
            this.error.push(
              'Оплата невозможна, отсутствуют необходимые данные, пожалуйста обновите смену или перезапустите приложение');
            Sentry.withScope(scope => {
                scope.setExtras({
                    routeId: routeId,
                    payments: payments,
                    payMethod: payMethod,
                    sum: sum,
                });
                Sentry.captureMessage('[RequestError] No data in routePay', Sentry.Severity.Error);
            });
            return Promise.reject();
        }
        this.isSync = true;
        return ShiftRequests.routePay({
            route_id: routeId,
            payments: this.paymentDataMatching(payments, payMethod, sum)
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                for (let i = 0; i < payments.length; i++) {
                    payments[i].paid = true;
                }
            });
        }).catch((error: RoutePayError) => {
            if (error.status === 404 && error.data?.orders) {
                runInAction(() => {
                    for (let i = 0; i < payments.length; i++) {
                        for (let k = 0; k < error.data.orders.length; k++) {
                            if (payments[i].id !== error.data.orders[k]) payments[i].paid = true;
                        }
                    }
                });
            }
            if (error.status === 421) {
                runInAction(() => {
                    for (let i = 0; i < payments.length; i++) {
                        payments[i].paid = true;
                    }
                });
            }
            this.errorHandler(error, 'routePay');
        });
    };

    @action routePayQR(
      jobId: number, orderId: number, cost: string): Promise<{ qr: string, payment_id: number } | null> {
        if (this.isSync) return Promise.reject();
        if (parseFloat(cost) === 0) {
            this.error.push('Оплата невозможна, итоговая сумма равна нулю');
            Sentry.captureMessage('[RequestError] Sum is 0 in routePay', Sentry.Severity.Error);
            return Promise.reject();
        }
        if (!jobId || !orderId) {
            this.error.push(
              'Оплата невозможна, отсутствуют необходимые данные, пожалуйста обновите смену или перезапустите приложение');
            Sentry.withScope(scope => {
                scope.setExtras({
                    routeId: jobId,
                    paymentId: orderId,
                    payMethod: 'qr',
                    sum: cost,
                });
                Sentry.captureMessage('[RequestError] No data in routePayQR', Sentry.Severity.Error);
            });
            return Promise.reject();
        }
        this.isSync = true;
        return ShiftRequests.routePayQR({
            job_id: jobId,
            order_id: orderId,
            cost: cost,
        }).then((e) => {
            runInAction(() => {
                this.isSync = false;
            });
            return Promise.resolve(e.data);
        }).catch((error: RoutePayError) => {
            this.errorHandler(error, 'routePay');
            return Promise.reject();
        });
    };

    @action routePayCheckStatus(paymentId: number): Promise<RoutePayCheckStatusResponse | null> {
        if (!paymentId) {
            Sentry.withScope(scope => {
                scope.setExtras({
                    paymentId: paymentId,
                });
                Sentry.captureMessage('[RequestError] No data in routePayCheckStatus', Sentry.Severity.Error);
            });
            return Promise.reject();
        }
        return ShiftRequests.routePayCheckStatus(paymentId).catch((error: RoutePayError) => {
            this.errorHandler(error, 'routePayCheckStatus');
            return Promise.reject();
        });
    };

    @action orderCancel(orderId: number, cause: string, route: ShiftRoute): void {
        if (this.isSync) return;
        if (!orderId || !cause || !route) {
            this.error.push(
              'Невозможно отменить заказ, отсутствуют необходимые данные, пожалуйста обновите смену или перезапустите приложение');
            Sentry.withScope(scope => {
                scope.setExtras({
                    orderId: orderId,
                    cause: cause,
                    routeId: route.route_id,
                });
                Sentry.captureMessage('[RequestError] No data in orderCancel', Sentry.Severity.Error);
            });
            return;
        }
        this.isSync = true;
        ShiftRequests.orderCancel({
            order_id: orderId,
            cause: cause
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                route.orders = route.orders.filter(order => order.order_id !== orderId);
                route.payments = route.payments.filter(payment => payment.id !== orderId);
            });
        }).catch((error: ErrorResponse) => {
            if (error.status === 421) {
                runInAction(() => {
                    route.orders = route.orders.filter(order => order.order_id !== orderId);
                    route.payments = route.payments.filter(payment => payment.id !== orderId);
                });
            }
            this.errorHandler(error, 'orderCancel');
        });
    };

    @action orderDisagreement(photo: any, order: ShiftRouteOrder, route: ShiftRoute, productsDisagreement: ShiftRouteProduct[], isZeroOrder: boolean): void {
        if (this.isSync) return;
        if (!photo || !order || !route || !productsDisagreement.length) {
            this.error.push('Невозможно составить разногласия, отсутствуют необходимые данные, пожалуйста обновите смену или перезапустите приложение');
            Sentry.withScope(scope => {
                scope.setExtras({
                    photo: photo.substr(0, 10),
                    orderId: order.order_id,
                    routeId: route.route_id,
                    productsDisagreement: productsDisagreement
                });
                Sentry.captureMessage('[RequestError] No data in orderDisagreement', Sentry.Severity.Error);
            });
            return;
        }
        this.isSync = true;
        const formData: FormData = new FormData();
        let index: number = 0;
        formData.append('order_id', order.order_id.toString());
        formData.append('photo', new Blob([photo], {type: 'image/jpeg'}));
        for (let i = 0; i < productsDisagreement.length; i++) {
            formData.append(`products[${index}][product_count]`, productsDisagreement[i].disagreement_count.toString());
            formData.append(`products[${index}][product_number]`, productsDisagreement[i].product_number);
            formData.append(`products[${index}][product_id]`, productsDisagreement[i].product_id);
            formData.append(`products[${index}][cause]`, productsDisagreement[i].disagreement_cause);
            index++;
        }
        ShiftRequests.orderDisagreement(formData).then(e => {
            runInAction(() => {
                this.isSync = false;
                order.disagreement = true;
                if (e.total_amount) {
                    for (let i = 0; i < route.payments.length; i++) {
                        if (route.payments[i].id === order.order_id) route.payments[i].cost = e.total_amount.toString();
                    }
                }
                if (isZeroOrder) {
                    route.orders = route.orders.filter(el => el.order_id !== order.order_id);
                    route.payments = route.payments.filter(el => el.id !== order.order_id);
                }
                this.shiftOrderDisagreementId = null;
            });
        }).catch((error: OrderDisagreementError) => {
            runInAction(() => {
                this.shiftOrderDisagreementId = null;
            });
            if (error.status === 421) {
                runInAction(() => {
                    order.disagreement = true;
                    if (error.data?.total_amount) {
                        for (let i = 0; i < route.payments.length; i++) {
                            if (route.payments[i].id === order.order_id) route.payments[i].cost = error.data.total_amount.toString();
                        }
                    }
                    if (isZeroOrder) {
                        route.orders = route.orders.filter(el => el.order_id !== order.order_id);
                        route.payments = route.payments.filter(el => el.id !== order.order_id);
                    }
                });
            }
            this.errorHandler(error, 'orderDisagreement');
        });
    };

    @action startShift(event: CustomEvent<RefresherEventDetail> | null): void {
        if (this.isSync) return;
        this.isSync = true;
        if (event) {
            Sentry.addBreadcrumb({
                category: 'event',
                message: 'Manual Shift Update',
                level: Sentry.Severity.Warning
            });
        }
        ShiftRequests.start().then(e => {
            if (event) event.detail.complete();
            this.getDisagreementList();
            runInAction(() => {
                this.isSync = false;
                this.shiftId = e.shift_id || null;
                this.shiftDate = e.data?.date || '';
                this.shiftOrderDisagreementId = null;
                this.shiftRoutes = e.data?.routes || [];
                this.shiftStart = true;
            });
            Sentry.setExtras({
                'Shift ID': e.shift_id,
                'Shift Date': e.data.date
            });
        }).catch((error: ErrorResponse) => {
            if (event) event.detail.complete();
            this.errorHandler(error, 'startShift');
        });
    };

    @action endShift(): void {
        if (this.isSync) return;
        if (this.shiftId === null) return;
        this.isSync = true;
        const formData: FormData = new FormData();
        formData.append('shift_id', this.shiftId.toString());
        for (let i = 0; i < this.shiftCollections.length; i++) {
            formData.append(`report[${i}][collection_id]`, this.shiftCollections[i].collection_id.toString());
            formData.append(`report[${i}][sum]`, this.shiftCollections[i].manual_balance.toString());
            formData.append(`report[${i}][photo]`, new Blob([this.shiftCollections[i].photo], {type: 'image/jpeg'}));
        }
        ShiftRequests.end(formData).then(() => {
            runInAction(() => {
                this.isSync = false;
                this.shiftCloseSuccess = true;
            });
        }).catch((error: ErrorResponse) => {
            if (error.status === 421) {
                runInAction(() => {
                    this.shiftCloseSuccess = true;
                });
            }
            this.errorHandler(error, 'endShift');
        });
    };

    @action getShiftInfo(shiftId: number | null, event: CustomEvent<RefresherEventDetail> | null): void {
        if (this.isSync) return;
        if (shiftId === null) return;
        this.isSync = true;
        if (event) {
            Sentry.addBreadcrumb({
                category: 'event',
                message: 'Manual Collections Update',
                level: Sentry.Severity.Warning
            });
        }
        ShiftRequests.info({
            shift_id: shiftId
        }).then(e => {
            if (event) event.detail.complete();
            runInAction(() => {
                this.isSync = false;
                this.shiftTasks = e.data?.tasks || [];
                this.shiftCollections = e.data?.collection || [];
                this.shiftCards = e.data?.cards || [];
            });
        }).catch((error: ErrorResponse) => {
            if (event) event.detail.complete();
            this.errorHandler(error, 'getShiftInfo');
        });
    };

    @action getDisagreementList(): void {
        ShiftRequests.disagreementList().then(e => {
            if (!e.data.length) Sentry.captureMessage('[Error] Disagreement list is empty', Sentry.Severity.Error);
            this.updateApp(true);
            runInAction(() => {
                this.disagreementList = e.data || [];
            });
        }).catch((error: ErrorResponse) => this.errorHandler(error, 'getDisagreementList'));
    };


    @action.bound errorHandler(error: ErrorResponse, context: string): void {
        this.isSync = false;
        let errorMessage: string = error.data?.message || 'На сервере произошла неизвестная ошибка';
        let authErrorMessage: string = '';
        switch (error.status) {
            case 401:
                authErrorMessage = 'Нет токена авторизации, пожалуйста перезайдите в свой аккаунт';
                break;
            case 403:
                authErrorMessage = 'Токен авторизации истек, пожалуйста перезайдите в свой аккаунт';
                break;
            case 404:
                if (context === 'taskClose') {
                    if (error.data?.message === 'Task not found') {
                        authErrorMessage = '';
                        errorMessage = 'Задача не найдена';
                    }
                }
                if (context === 'startShift') {
                    if (error.data?.message === 'Shift not found') {
                        authErrorMessage = '';
                        errorMessage = 'На данный момент у вас нет активных смен';
                    }
                }
                if (context === 'routePay') {
                    authErrorMessage = '';
                    errorMessage = 'Некоторые платежные документы не были найдены в базе данных, оплата по ним не прошла';
                }
                if (error.data?.message === 'User not found') {
                    authErrorMessage = 'Доступ запрещён, пожалуйста перезайдите в свой аккаунт';
                    errorMessage = '';
                }
                if (error.data?.message === 'Route not found') {
                    authErrorMessage = '';
                    errorMessage = 'Маршрут не найден';
                }
                break;
            case 421:
                if (context === 'routeStart' || context === 'routeArrived') {
                    errorMessage = 'СМС-сервер не доступен, возможно клиент не получит уведомление';
                }
                if (context === 'routePay') {
                    errorMessage = 'Невозможно установить соединение с 1С, оплата сохранена и при первой возможности чек будет отправлен клиенту';
                }
                if (context === 'orderDisagreement' || context === 'orderCancel' || context === 'endShift') {
                    errorMessage = 'Невозможно установить соединение с 1С, данные сохранены и при первой возможности будут синхронизированы';
                }
                break;
            case 500:
                errorMessage = 'На сервере произошла неизвестная ошибка';
                break;
            case 12002:
                errorMessage = 'Не удалось получить данные от сервера, истекло время ожидания ответа';
                break;
            case 12029:
                errorMessage = 'Отсутствует подключение к сети Интернет';
                break;
        }
        if (errorMessage) this.error.push(errorMessage);
        if (authErrorMessage) this.authError = authErrorMessage;
    };
}

export const shiftStore = new ShiftStore();
