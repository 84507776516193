import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {closeSharp} from 'ionicons/icons';
import {RouteComponentProps, useHistory} from "react-router";
import {inject, observer} from "mobx-react";
import {ShiftRoute, ShiftStore} from "../stores/ShiftStore";
import ShiftRouteTasks from "../components/ShiftRouteTasks";
import ShiftRouteAddress from "../components/ShiftRouteAddress";
import ShiftRoutePayments from "../components/ShiftRoutePayments";
import ShiftRouteActions from "../components/ShiftRouteActions";
import ShiftRouteConnect from "../components/ShiftRouteConnect";
import ShiftRouteOrders from "../components/ShiftRouteOrders";

interface CustomProps extends RouteComponentProps<{ id: string; }> {
    shiftStore: ShiftStore;
}

const RouteDetail: React.FC<CustomProps> = ({match, shiftStore}) => {
    const history = useHistory();
    const [route, setRoute] = useState<ShiftRoute | null>(null);

    useEffect(() => {
        if (!shiftStore.shiftStart) history.replace('/shift/start');
    }, [shiftStore.shiftStart, history]);

    useEffect(() => {
        const route: ShiftRoute | null = shiftStore.getRouteById(match.params.id);
        if (route === null) history.replace('/shift/routes');
        setRoute(route);
    }, [match.params.id, shiftStore, shiftStore.shiftRoutes, history]);

    if (route === null) return <></>;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton className="btn-close" routerLink="/shift/routes" routerDirection="back">
                            <IonIcon className="icon-close" icon={closeSharp}/>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{route.legal_name || "Название отсутствует"}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={e => shiftStore.startShift(e)}>
                    <IonRefresherContent/>
                </IonRefresher>
                <ShiftRouteTasks shiftStore={shiftStore} tasks={route.tasks}/>
                <ShiftRouteAddress address={route.address || route.address_short}/>
                {shiftStore.shiftRouteState[route.route_id] === 'arrived' &&
                <ShiftRoutePayments shiftStore={shiftStore} routeId={route.route_id} payments={route.payments}/>}
                <ShiftRouteActions shiftStore={shiftStore} route={route}/>
                <ShiftRouteConnect shiftStore={shiftStore} contacts={route.contacts}/>
                <ShiftRouteOrders shiftStore={shiftStore} route={route}/>
            </IonContent>
        </IonPage>
    );
};

export default inject('shiftStore')(observer(RouteDetail));
