import React from "react";
import {observer} from "mobx-react";
import {IonCardHeader, IonCardSubtitle, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {navigate} from "ionicons/icons";

interface Props {
    address: string;
}

const ShiftRouteAddress: React.FC<Props> = ({address}) => {
    return (
        <div className="content-block pb-6">
            <IonCardHeader className="border">
                <IonCardSubtitle>Адрес</IonCardSubtitle>
            </IonCardHeader>
            {address === '' ? (
                <IonItem lines="none" className="pointer-none">
                    <IonLabel className="ion-text-wrap">Адрес отсутствует</IonLabel>
                </IonItem>
            ) : (
                <IonItem lines="none" href={`yandexnavi://map_search?text=${address}`}>
                    <IonLabel className="ion-text-wrap">{address}</IonLabel>
                    <IonIcon className="icon-navigate" slot="start" icon={navigate}/>
                </IonItem>
            )}
        </div>
    );
};

export default observer(ShiftRouteAddress);
