import React from 'react';
import {IonApp, IonLoading, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import Auth from './pages/Auth';
import Page from './pages/Page';
import {observer} from "mobx-react";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/App.scss';
import {authStore} from './stores/AuthStore';
import {shiftStore} from './stores/ShiftStore';

const App: React.FC = () => {
    if (!authStore.storeInit || !shiftStore.storeInit) {
        return (
            <IonApp>
                <IonLoading isOpen={true}/>
            </IonApp>
        );
    }

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route path="/auth" component={Auth} exact/>
                    <Route path="/shift" component={Page}/>
                    <Route render={() => <Redirect to="/auth"/>}/>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

export default observer(App);
