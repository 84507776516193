import * as Sentry from '@sentry/browser';
import {persist} from "mobx-persist";
import {action, computed, observable, runInAction} from "mobx";
import {AuthRequests, ErrorResponse} from "../api/Requests";
import {shiftStore} from "./ShiftStore";

export class AuthStore {
    @persist('object') @observable authToken: string = '';
    @persist('object') @observable userPhone: string = '';
    @persist('object') @observable userName: string = '';
    @observable storeInit: boolean | null = null;
    @observable authError: string | null = null;
    @observable isSync: boolean = false;
    @observable getCode: boolean = false;
    @observable code: string = '';

    @computed get isAuth(): boolean {
        return this.authToken !== '';
    };

    @computed get checkValidUserPhone(): boolean {
        if (this.userPhone.length < 10) return false;
        return !this.userPhone.match(/\D/g);
    };

    @computed get prettyPhone(): string {
        return this.userPhone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
    };


    @action setUserPhone(phone: string | number): void {
        this.userPhone = phone.toString();
        this.authError = null;
    };

    @action setUserName(name: string): void {
        this.userName = name;
    };

    @action setAuthToken(token: string): void {
        this.authToken = token;
    };

    @action logout(): void {
        Sentry.setUser({
            'username': 'Unauthorized',
            'Phone Number': '+7'
        });
        this.authToken = '';
        this.userName = '';
        this.getCode = false;
        this.code = '';
        this.authError = null;
    };

    @action setInit(): void {
        this.storeInit = true;
        Sentry.setUser({
            'username': this.userName === '' ? 'Unauthorized' : this.userName,
            'Phone Number': this.userName === '' ? '+7' : `+7${this.userPhone}`
        });
        Sentry.setExtra('Token', this.authToken);
    };

    @action setGetCode(state: boolean): void {
        this.getCode = state;
        this.code = '';
        this.authError = null;
    };

    @action setCode(code: string | number): void {
        this.code = code.toString();
        this.authError = null;
    };


    @action sendPhone(): void {
        if (this.isSync) return;
        if (!this.checkValidUserPhone) {
            Sentry.captureMessage('[RequestError] No phone_number in sendPhone', Sentry.Severity.Error);
            return;
        }
        this.isSync = true;
        this.authError = null;
        AuthRequests.sendPhone({
            phone_number: `+7${this.userPhone}`
        }).then(() => {
            runInAction(() => {
                this.isSync = false;
                this.getCode = true;
            });
        }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendPhone'));
    };

    @action sendCode(): void {
        if (this.isSync) return;
        if (!this.checkValidUserPhone || !this.code) {
            Sentry.withScope(scope => {
                scope.setExtras({
                    phone_number: `+7${this.userPhone}`,
                    sms_code: this.code
                });
                Sentry.captureMessage('[RequestError] No data in sendCode', Sentry.Severity.Error);
            });
            return;
        }
        this.isSync = true;
        this.authError = null;
        AuthRequests.sendCode({
            phone_number: `+7${this.userPhone}`,
            sms_code: this.code
        }).then(e => {
            runInAction(() => {
                shiftStore.resetShift();
                this.isSync = false;
                this.userName = `${e.data?.last_name} ${e.data?.first_name}`;
                this.getCode = false;
                this.code = '';
                this.authToken = e.data?.token || '';
            });
            Sentry.setUser({
                'username': `${e.data.last_name} ${e.data.first_name}`,
                'Phone Number': `+7${this.userPhone}`
            });
            Sentry.setExtra('Token', e.data.token);
        }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendCode'));
    };


    @action.bound errorHandler(error: ErrorResponse, context: string): void {
        this.isSync = false;
        let errorMessage: string = error.data?.message || 'На сервере произошла неизвестная ошибка';
        switch (error.status) {
            case 403:
                errorMessage = 'Доступ запрещён';
                if (context === 'sendCode') errorMessage = 'Неверный код';
                break;
            case 404:
                errorMessage = 'Пользователь не найден';
                break;
            case 421:
                errorMessage = 'SMS-сервис временно не доступен, попробуйте позже';
                break;
            case 500:
                errorMessage = 'На сервере произошла неизвестная ошибка';
                break;
            case 12002:
                errorMessage = 'Не удалось получить данные от сервера, истекло время ожидания ответа';
                break;
            case 12029:
                errorMessage = 'Отсутствует подключение к сети Интернет';
                break;
        }
        this.authError = errorMessage;
    };
}

export const authStore = new AuthStore();
