import { CameraResultType, Plugins } from '@capacitor/core';
import React, { useState } from 'react';
import { ShiftCollection, ShiftStore } from '../stores/ShiftStore';
import { observer } from 'mobx-react';
import {
    IonButton,
    IonCardHeader,
    IonCardSubtitle,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonPopover,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from '@ionic/react';

interface Props {
    shiftStore: ShiftStore;
}

interface PropsCollection {
    shiftStore: ShiftStore;
    collection: ShiftCollection;
}

const ShiftCollections: React.FC<Props> = ({shiftStore}) => {
    return (
        <div className="content-block">
            <IonCardHeader>
                <IonCardSubtitle>Инкассация</IonCardSubtitle>
            </IonCardHeader>
            <IonList>
                {shiftStore.shiftCollections.length === 0 && (
                    <IonItem lines="none">
                        <IonLabel className="ion-text-wrap">
                            {shiftStore.shiftCards.length > 0 ? `Сумма для инкассации меньше минимально возможной (${shiftStore.shiftCards[0].credit} ₽)` : 'Банковские карты отсутствуют'}
                        </IonLabel>
                    </IonItem>
                )}
                {shiftStore.shiftCollections.length !== 0 && shiftStore.shiftCollections.map((collection, i) => (
                    <Collection shiftStore={shiftStore} collection={collection} key={collection.collection_id || i}/>
                ))}
            </IonList>
        </div>
    );
};

const Collection: React.FC<PropsCollection> = observer(({shiftStore, collection}) => {
    useIonViewWillEnter(() => resetState());
    useIonViewWillLeave(() => resetState());

    const [showPopoverInfo, setShowPopoverInfo] = useState<boolean>(false);
    const [showPopoverValue, setShowPopoverValue] = useState<boolean>(false);
    const [isInvalidValue, setIsInvalidValue] = useState<boolean>(false);
    const [manualBalance, setManualBalance] = useState<string>('');
    const handleShowInfo = (e: any): void => {
        e.preventDefault();
        setShowPopoverInfo(true);
    };
    const handleHideInfo = (): void => setShowPopoverInfo(false);
    const handleHideValue = (): void => {
        if (manualBalance) collection.manual_balance = parseFloat(manualBalance);
        setShowPopoverValue(false);
    }
    const handlePhoto = (): void => {
        setShowPopoverInfo(false);
        const {Camera} = Plugins;
        Camera.getPhoto({
            quality: 50,
            allowEditing: false,
            height: 1000,
            resultType: CameraResultType.Base64
        }).then((e) => {
            collection.photo = 'data:image/jpeg;base64,' + e.base64String;
            setManualBalance(shiftStore.collectionBalanceRound(collection.balance_info.balance_ending));
            setShowPopoverValue(true);
        }).catch(e => console.error(e));
    };
    const handleInput = (e: any): void => {
        setManualBalance(e.currentTarget.value);
        setIsInvalidValue(!shiftStore.checkValidCost(e.currentTarget.value));
    };
    const resetState = (): void => {
        setShowPopoverInfo(false);
        setShowPopoverValue(false);
        setIsInvalidValue(false);
        setManualBalance('');
    };

    return (
        <div>
            <IonItem href="#" onClick={handleShowInfo}>
                <IonLabel className="ion-text-wrap">{shiftStore.cardNumberFormat(collection.collection_name)}</IonLabel>
                <IonNote slot="end">{shiftStore.priceFormat(collection.collection_balance)} ₽</IonNote>
            </IonItem>

            <IonPopover
                isOpen={showPopoverInfo}
                onDidDismiss={handleHideInfo}
            >
                <div className="collection-info">
                    <div className="collection-info__wrap">
                        <div className="collection-info__row">
                            <div className="collection-info__text">Остаток на начало</div>
                            <div className="collection-info__value color-black">
                                {shiftStore.priceFormat(collection.balance_info.balance_begin)} ₽
                            </div>
                        </div>
                        {collection.balance_info.history.map((item, i) => (
                            <div className="collection-info__row" key={i}>
                                <div className="collection-info__text">
                                    {item.type === 'fundraising' ?
                                        <span className="color-green">+ {shiftStore.priceFormat(item.cost)}</span> :
                                        <span className="color-red">- {shiftStore.priceFormat(item.cost)}</span>}
                                    {" "}
                                    {item.title}
                                </div>
                                <div className="collection-info__value">{item.time.substr(0, 5)}</div>
                            </div>
                        ))}
                    </div>
                    <div className="collection-info__row">
                        <div className="collection-info__text">Остаток на конец смены</div>
                        <div className="collection-info__value color-black">
                            {shiftStore.priceFormat(collection.balance_info.balance_ending)} ₽
                        </div>
                    </div>
                </div>
                <div className="popover-buttons">
                    <IonButton fill="clear" color="medium" className="secondary" onClick={handlePhoto}>
                        Сфотографировать ЧЕК
                    </IonButton>
                    <IonButton fill="clear" onClick={handleHideInfo}>OK</IonButton>
                </div>
            </IonPopover>

            <IonPopover
                isOpen={showPopoverValue}
                backdropDismiss={false}
                onDidDismiss={handleHideValue}
            >
                <IonItem className="start-0 mt-n16">
                    <IonLabel className="mt-30" slot="end">₽</IonLabel>
                    <IonLabel className="code-label" position="floating">Введите сумму на чеке</IonLabel>
                    <IonInput className={isInvalidValue ? '_error' : ''}
                              onInput={handleInput}
                              value={manualBalance}
                              type="tel"
                              inputMode="tel"
                    />
                </IonItem>
                <div className="popover-buttons">
                    <IonButton fill="clear" onClick={handleHideValue} disabled={isInvalidValue}>OK</IonButton>
                </div>
            </IonPopover>
        </div>
    );
});

export default observer(ShiftCollections);
