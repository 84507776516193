const sec = 1_000; // ms
const min = 60 * sec;

//export const API_ROOT: string = 'https://parfum.hpdev.ru/api/v1/'; //prod
//export const API_ROOT: string = 'https://parfum2.hpdev.ru/api/v1/'; //dev
export const API_ROOT: string = 'https://vks.parfum-altai.ru/api/v1/';

export const PATH_UPDATE_XML: string = 'https://app.parfum.hpdev.ru/update.xml';
export const PATH_UPDATE_XML_DEV: string = 'https://app.parfum.hpdev.ru/update_dev.xml';
export const TEST_PHONE_NUMBER: string = '9069652197';

export const SMS_CODE_DURATION: number = 60; //seconds

export const REQUEST_TIMEOUT: number = 2 * min;

export const VERSION: string = '1.7.10'; //release.features.bugfixes
