import {IonLoading, IonRouterOutlet, IonSplitPane} from '@ionic/react';
import React, {useEffect} from 'react';
import Errors from '../components/Errors';
import Menu from "../components/Menu";
import {ShiftStore} from '../stores/ShiftStore';
import Routes from './Routes';
import End from './End';
import {Route} from "react-router-dom";
import {AuthStore} from "../stores/AuthStore";
import {inject, observer} from "mobx-react";
import Start from "./Start";
import {RouteComponentProps, useHistory} from "react-router";
import RouteDetail from "./RouteDetail";

interface CustomProps extends RouteComponentProps {
    authStore: AuthStore;
    shiftStore: ShiftStore;
}

const Page: React.FC<CustomProps> = (props) => {
    const history = useHistory();

    useEffect(() => {
        if (!props.authStore.isAuth) history.replace('/auth');
    }, [props.authStore.isAuth, history]);

    return (
        <>
            <IonSplitPane contentId="main">
                {props.shiftStore.shiftStart && <Menu {...props} />}

                <IonRouterOutlet id="main">
                    <Route path={`${props.match.url}/start`} component={Start} exact/>
                    <Route path={`${props.match.url}/routes`} component={Routes} exact/>
                    <Route path={`${props.match.url}/routes/:id`} component={RouteDetail} exact/>
                    <Route path={`${props.match.url}/end`} component={End} exact/>
                    <Route path={`${props.match.url}/end/:part`} component={End} exact/>
                </IonRouterOutlet>
            </IonSplitPane>
            <Errors shiftStore={props.shiftStore}/>
            <IonLoading isOpen={props.shiftStore.isSync}/>
        </>
    );
};

export default inject('authStore', 'shiftStore')(observer(Page));
