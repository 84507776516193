import {CameraResultType, Plugins} from '@capacitor/core';
import {AlertInput} from '@ionic/core';
import * as Sentry from '@sentry/browser';
import React, {useEffect, useState} from "react";
import {ShiftRoute, ShiftRouteOrder, ShiftRouteProduct, ShiftStore} from "../stores/ShiftStore";
import {observer} from "mobx-react";
import {
    IonAlert,
    IonButton,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    useIonViewWillEnter,
    useIonViewWillLeave
} from "@ionic/react";
import {chevronDownSharp, chevronUpSharp} from "ionicons/icons";

interface Props {
    shiftStore: ShiftStore;
    route: ShiftRoute;
}

interface PropsOrders {
    orders: ShiftRouteOrder[];
    disagreementOrderId: number | null;

    handleOrderCancelInitial(orderId: number): void;

    handleDisagreementInitial(orderId: number): void;

    handleDisagreement(product: ShiftRouteProduct): boolean;

    handleShowAlertDisagreementPhoto(order: ShiftRouteOrder): void;
}

interface PropsProduct {
    product: ShiftRouteProduct;
    isDisagreement: boolean;
    isLast: boolean;

    handleDisagreement(product: ShiftRouteProduct): boolean;
}

const ShiftRouteOrders: React.FC<Props> = ({shiftStore, route}) => {
    useIonViewWillEnter(() => resetState());
    useIonViewWillLeave(() => resetState());

    useEffect(() => {
        if (!route.orders.length) return;
        const disagreementList: AlertInput[] = [];
        for (let i = 0; i < shiftStore.disagreementList.length; i++) {
            disagreementList.push({
                name: 'cause',
                type: 'radio',
                label: shiftStore.disagreementList[i].description,
                value: shiftStore.disagreementList[i].key,
                checked: !i
            })
        }
        setDisagreementList(disagreementList);
    }, [shiftStore.disagreementList, route.orders]);

    const [showAlertOrderCancel, setShowAlertOrderCancel] = useState<boolean>(false);
    const [showAlertDisagreement, setShowAlertDisagreement] = useState<boolean>(false);
    const [showAlertDisagreementPhoto, setShowAlertDisagreementPhoto] = useState<boolean>(false);
    const handleHideAlertOrderCancel = (): void => setShowAlertOrderCancel(false);
    const handleHideAlertDisagreement = (e: any): void => {
        setShowAlertDisagreement(false);
        if (tempProduct) tempProduct.disagreement_cause = e.detail.data.values;
    };
    const handleHideAlertDisagreementPhoto = (): void => setShowAlertDisagreementPhoto(false);
    const handleShowAlertDisagreementPhoto = (order: ShiftRouteOrder): void => {
        const productsDisagreement: ShiftRouteProduct[] = [];
        let isZeroOrder: number = 0;
        for (let i = 0; i < order.products.length; i++) {
            if (order.products[i].disagreement_checked && order.products[i].product_count !== order.products[i].disagreement_count) {
                productsDisagreement.push(order.products[i]);
                isZeroOrder += order.products[i].disagreement_count;
            } else isZeroOrder += order.products[i].product_count;
        }
        setProductsDisagreement(productsDisagreement);
        setIsZeroOrder(isZeroOrder === 0);
        if (productsDisagreement.length) {
            setTempOrder(order);
            setShowAlertDisagreementPhoto(true);
        } else shiftStore.shiftOrderDisagreementId = null;
    };

    const [disagreementList, setDisagreementList] = useState<AlertInput[]>([]);
    const [tempOrderId, setTempOrderId] = useState<number | null>(null);
    const [tempProduct, setTempProduct] = useState<ShiftRouteProduct | null>(null);
    const [tempOrder, setTempOrder] = useState<ShiftRouteOrder | null>(null);
    const [productsDisagreement, setProductsDisagreement] = useState<ShiftRouteProduct[] | null>(null);
    const [isZeroOrder, setIsZeroOrder] = useState<boolean>(false);
    const handleOrderCancelInitial = (orderId: number): void => {
        if (!disagreementList.length) {
            shiftStore.error.push('Невозможно отменить заказ, список причин разногласий пуст, пожалуйста обновите смену или перезапустите приложение');
            return;
        }
        setTempOrderId(orderId);
        setShowAlertOrderCancel(true);
    };
    const handleOrderCancel = (cause: string): void => {
        if (tempOrderId && cause) shiftStore.orderCancel(tempOrderId, cause, route);
    }
    const handleDisagreementInitial = (orderId: number): void => {
        shiftStore.shiftOrderDisagreementId = orderId;
    }
    const handleDisagreement = (product: ShiftRouteProduct): boolean => {
        if (!disagreementList.length) {
            shiftStore.error.push('Невозможно составить разногласия, список причин разногласий пуст, пожалуйста обновите смену или перезапустите приложение');
            return false;
        }
        setTempProduct(product);
        setShowAlertDisagreement(true);
        return true;
    };
    const handleDisagreementEnd = (): void => {
        const {Camera} = Plugins;
        Camera.getPhoto({
            quality: 50,
            allowEditing: false,
            height: 1000,
            resultType: CameraResultType.Base64
        }).then(e => {
            if (tempOrder && productsDisagreement?.length) {
                shiftStore.orderDisagreement('data:image/jpeg;base64,' + e.base64String, tempOrder, route, productsDisagreement, isZeroOrder);
            } else {
                shiftStore.error.push('Список разногласий пуст<br>Пожалуйста составьте список еще раз');
                shiftStore.shiftOrderDisagreementId = null;
                Sentry.captureMessage('[RequestError] No data in orderDisagreement', Sentry.Severity.Error);
            }
        }).catch(e => console.error(e));
    };
    const resetState = (): void => {
        shiftStore.shiftOrderDisagreementId = null;
        setShowAlertOrderCancel(false);
        setShowAlertDisagreement(false);
        setShowAlertDisagreementPhoto(false);
        setTempOrderId(null);
        setTempProduct(null);
        setTempOrder(null);
        setProductsDisagreement(null);
        setIsZeroOrder(false);
    };

    return (
        <>
            <IonAlert
                cssClass="alert-order-cancel"
                isOpen={showAlertOrderCancel}
                onDidDismiss={handleHideAlertOrderCancel}
                backdropDismiss={false}
                header={'Причина отмены'}
                inputs={disagreementList}
                buttons={[
                    {
                        text: 'Отмена',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'OK',
                        handler: handleOrderCancel
                    }
                ]}
            />

            <IonAlert
                cssClass="alert-disagreement"
                isOpen={showAlertDisagreement}
                onDidDismiss={handleHideAlertDisagreement}
                backdropDismiss={false}
                header={'Причина разногласий'}
                inputs={disagreementList}
                buttons={['OK']}
            />

            <IonAlert
                cssClass="alert-disagreement-photo"
                isOpen={showAlertDisagreementPhoto}
                onDidDismiss={handleHideAlertDisagreementPhoto}
                header={'Акт разногласий'}
                buttons={[
                    {
                        text: 'СФОТОГРАФИРОВАТЬ АКТ',
                        cssClass: 'photo',
                        handler: handleDisagreementEnd
                    }, 'Отмена'
                ]}
            />

            <div className="content-block">
                {route.orders.length === 0 ? (
                    <IonItem lines="none">
                        <IonLabel className="ion-text-wrap pt-12">Заказы отсутствуют</IonLabel>
                    </IonItem>
                ) : (
                    <Orders
                        orders={route.orders}
                        disagreementOrderId={shiftStore.shiftOrderDisagreementId}
                        handleOrderCancelInitial={handleOrderCancelInitial}
                        handleDisagreementInitial={handleDisagreementInitial}
                        handleDisagreement={handleDisagreement}
                        handleShowAlertDisagreementPhoto={handleShowAlertDisagreementPhoto}
                    />
                )}
            </div>
        </>
    );
};

const Orders: React.FC<PropsOrders> = observer(({orders, disagreementOrderId, handleOrderCancelInitial, handleDisagreementInitial, handleDisagreement, handleShowAlertDisagreementPhoto}) => {
    return (
        <>
            {orders.map((order, i) =>
                <div className="order-item disable-select" key={order.order_id || i}>
                    <IonCardHeader onClick={() => order.expanded = !order.expanded}>
                        <IonCardSubtitle className="ion-tappable">
                            Заказ №{order.order_name}
                            {order.expanded ? <IonIcon icon={chevronUpSharp}/> : <IonIcon icon={chevronDownSharp}/>}
                        </IonCardSubtitle>
                    </IonCardHeader>
                    {(order.expanded && order.products.length !== 0) && (
                        <>
                            <IonList>
                                {order.products.map((product, i) => (
                                    <Product
                                        product={product}
                                        isDisagreement={disagreementOrderId === order.order_id}
                                        handleDisagreement={handleDisagreement}
                                        isLast={(order.products.length === i + 1)}
                                        key={product.product_id || i}
                                    />
                                ))}
                            </IonList>
                            {!order.disagreement && (
                                <div className="btn-wrap">
                                    {disagreementOrderId === order.order_id &&
                                    <IonButton className="btn-secondary" expand="block" onClick={() => handleShowAlertDisagreementPhoto(order)}>ЗАВЕРШИТЬ РАЗНОГЛАСИЯ</IonButton>}
                                    {disagreementOrderId === null && (
                                        <>
                                            <IonButton className="btn-secondary"
                                                       expand="block"
                                                       onClick={() => handleDisagreementInitial(order.order_id)}>
                                                СОСТАВИТЬ РАЗНОГЛАСИЯ
                                            </IonButton>
                                            <IonButton className="btn-red"
                                                       expand="block"
                                                       onClick={() => handleOrderCancelInitial(order.order_id)}>
                                                ОТМЕНИТЬ ЗАКАЗ
                                            </IonButton>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {(order.expanded && order.products.length === 0) &&
                    <div className="btn-wrap">В заказе нет товаров</div>}
                </div>
            )}
        </>
    );
});

const Product: React.FC<PropsProduct> = observer(({product, isDisagreement, handleDisagreement, isLast}) => {
    useIonViewWillLeave(() => product.disagreement_checked = false);

    useEffect(() => {
        if (product.disagreement_count === undefined) product.disagreement_count = product.product_count;
    }, [product.disagreement_count, product.product_count]);

    const handleIncrement = (): void => {
        if (product.disagreement_count >= product.product_count) product.disagreement_count = product.product_count;
        else product.disagreement_count += 1
        if (product.disagreement_count === product.product_count) product.disagreement_checked = false;
    };
    const handleDecrement = (): void => {
        if (product.disagreement_count <= 0) product.disagreement_count = 0;
        else product.disagreement_count -= 1;
        if (product.disagreement_count === product.product_count) product.disagreement_checked = false;
    };
    const handleCheckboxClick = (e: React.MouseEvent<HTMLIonCheckboxElement, MouseEvent>): void => {
        if (product.disagreement_count === product.product_count) e.currentTarget.checked = false;
        product.disagreement_checked = e.currentTarget.checked;
        if (e.currentTarget.checked) {
            e.currentTarget.checked = product.disagreement_checked = handleDisagreement(product);
        }
    };

    return (
        <IonItem className="ion-tappable" lines={isLast ? 'none' : 'inset'}>
            <IonLabel className="ion-text-wrap">
                <div>
                    {product.product_name}
                    {" "}
                    <span className="color-muted no-wrap">({product.product_count} шт.)</span>
                    <a className="display-none" href="/"> </a>
                </div>
                <div>#{product.product_number}</div>
                <a className="display-none" href="/"> </a>
            </IonLabel>
            {isDisagreement && (
                <>
                    {product.disagreement_count !== product.product_count && (
                        <IonCheckbox className="ion-tappable"
                                     onClick={handleCheckboxClick}
                                     checked={product.disagreement_checked}
                                     slot="start"/>
                    )}
                    <div className="counter" slot="end">
                        <div className={`counter-arrow${product.disagreement_count === 0 ? ' counter-arrow_disabled' : ' ion-tappable'}`}
                             onClick={handleDecrement}>
                            <IonIcon icon={chevronDownSharp}/>
                        </div>
                        <div className={`counter-num${product.disagreement_count !== product.product_count ? ' counter-num_new' : ''}`}>
                            {product.disagreement_count}
                        </div>
                        <div
                            className={`counter-arrow${product.disagreement_count === product.product_count ? ' counter-arrow_disabled' : ' ion-tappable'}`}
                            onClick={handleIncrement}>
                            <IonIcon icon={chevronUpSharp}/>
                        </div>
                    </div>
                </>
            )}
        </IonItem>
    );
});

export default observer(ShiftRouteOrders);
