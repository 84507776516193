import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {API_ROOT, VERSION} from './config';
import * as serviceWorker from './serviceWorker';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {Provider} from "mobx-react";
import {create} from "mobx-persist";
import {authStore} from "./stores/AuthStore";
import {shiftStore} from "./stores/ShiftStore";
import 'mobx-react-lite/batchingForReactDom';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: "https://6f998d7a665743ac8ce796d116ecee3d@sentry.hpdev.ru/11",
    environment: (API_ROOT.indexOf('parfum2') !== -1) ? 'Dev' : 'Prod',
    maxBreadcrumbs: 20,
    beforeSend(event) {
        if (event.request?.url) {
            event.request.url = event.request.url.replace(/^https?:\/\/localhost:?(\d?)+/, '');
        }
        return event;
    },
    beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === 'fetch') return null;
        if (breadcrumb.category === 'xhr') return null;
        if (breadcrumb.category === 'ui.input') return null;
        if (breadcrumb.category === 'ui.click') return null;
        return breadcrumb;
    }
});
Sentry.setTag('Version', VERSION);

const hydrate = create({});
hydrate('authStore', authStore).then(() => authStore.setInit());
hydrate('shiftStore', shiftStore).then(() => shiftStore.setInit());

ReactDOM.render(
    <Provider authStore={authStore} shiftStore={shiftStore}><App/></Provider>, document.getElementById('root'));

defineCustomElements(window).catch(e => console.error(e));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
