import React from "react";
import {ShiftRouteTask, ShiftStore} from "../stores/ShiftStore";
import {observer} from "mobx-react";
import {IonCardHeader, IonCardSubtitle, IonCheckbox, IonItem, IonLabel, IonList} from "@ionic/react";

interface Props {
    shiftStore: ShiftStore;
    tasks: ShiftRouteTask[];
}

const ShiftRouteTasks: React.FC<Props> = ({shiftStore, tasks}) => {
    const handleTaskClose = (task: ShiftRouteTask, e: React.MouseEvent<HTMLIonCheckboxElement, MouseEvent>): void => {
        e.currentTarget.checked = false;
        shiftStore.taskClose(task);
    };

    return (
        <div className="content-block">
            <IonCardHeader>
                <IonCardSubtitle>Задачи</IonCardSubtitle>
            </IonCardHeader>
            <IonList>
                {tasks.length === 0 && (
                    <IonItem lines="none">
                        <IonLabel className="ion-text-wrap">Задачи отсутствуют</IonLabel>
                    </IonItem>
                )}
                {tasks.length !== 0 && tasks.map((task, i) => (
                    <IonItem key={task.task_id || i}>
                        <IonLabel className="ion-text-wrap">{task.task_name || "Название отсутствует"}</IonLabel>
                        <IonCheckbox slot="start"
                                     checked={task.finished}
                                     disabled={task.finished}
                                     onClick={e => handleTaskClose(task, e)}/>
                    </IonItem>
                ))}
            </IonList>
        </div>
    );
};

export default observer(ShiftRouteTasks);
