import React, { useState, useEffect } from 'react';
import { ShiftRoutePayment, ShiftStore } from '../stores/ShiftStore';
import { observer } from 'mobx-react';
import {
  IonAlert,
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonPopover,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';

interface Props {
  shiftStore: ShiftStore;
  routeId: number;
  payments: ShiftRoutePayment[];
}

const ShiftRoutePayments: React.FC<Props> = ({ shiftStore, routeId, payments }) => {
  useIonViewWillEnter(() => resetPayments());
  useIonViewWillLeave(() => resetPayments());

  const [showPaymentList, setShowPaymentList] = useState<boolean>(false);
  const [showAlertPaymentAmount, setShowAlertPaymentAmount] = useState<boolean>(false);
  const [showAlertPaymentMixed, setShowAlertPaymentMixed] = useState<boolean>(false);
  const [showAlertPaymentQR, setShowAlertPaymentQR] = useState<boolean>(false);
  const [showAlertPaymentSuccess, setShowAlertPaymentSuccess] = useState<boolean>(false);
  const [codeQR, setCodeQR] = useState('');
  const [paymentId, setPaymentId] = useState<number | null>(null);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [isMultiselect, setIsMultiselect] = useState(false);
  const handleShowPaymentsList = (): void => {
    if (shiftStore.shiftOrderDisagreementId) {
      shiftStore.error.push('Сперва завершите разногласия');
      return;
    }
    setShowPaymentList(true);
  };
  const handleHidePaymentsList = (): void => {
    if (!showAlertPaymentAmount) resetPayments();
    setShowPaymentList(false);
  };
  const handleShowPaymentAmount = (): void => {
    if (isCustomPay) setToPay(parseFloat(toPay).toString());
    setShowAlertPaymentAmount(true);
    setShowAlertPaymentMixed(false);
    setShowPaymentList(false);
  };
  const handleHidePaymentAmount = (): void => setShowAlertPaymentAmount(false);
  const handleHidePaymentMixed = (): void => setShowAlertPaymentMixed(false);
  const handleHidePaymentQR = (): void => resetPayments();
  const handleHidePaymentSuccess = (): void => setShowAlertPaymentSuccess(false);

  const [totalSum, setTotalSum] = useState<string>('0');
  const [totalRequiredSum, setTotalRequiredSum] = useState<string>('0');
  const [toPay, setToPay] = useState<string>('0');
  const [toPayCash, setToPayCash] = useState<string>('0');
  const [toPayQR, setToPayQR] = useState<string>('0');
  const [toPayInvalid, setToPayInvalid] = useState<boolean>(false);
  const [toPayMixedInvalid, setToPayMixedInvalid] = useState<boolean>(false);
  const [isCustomPay, setIsCustomPay] = useState<boolean>(false);
  const handleSelectPayment = (payment: ShiftRoutePayment, e: any): void => {
    payment.checked = e.currentTarget.checked;
    calcTotalSum();
  };
  const handleCustomPay = (e: any): void => {
    setIsCustomPay(e.currentTarget.checked);
  };
  const handleInputToPay = (e: any): void => {
    setToPay(e.currentTarget.value);
    const floatValue = parseFloat(e.currentTarget.value);
    if (floatValue === 0) {
      setToPayInvalid(true);
      return;
    }
    const isValid = shiftStore.checkValidCost(e.currentTarget.value);
    setToPayInvalid(!isValid);
    if (isValid && floatValue < parseFloat(totalRequiredSum)) setToPayInvalid(true);
  };
  const handlePaymentAmountNext = (payMethod: ('cash' | 'qr' | 'mixed')): void => {
    setToPayCash(isCustomPay ? toPay : totalSum);
    setToPayQR('0');
    if (payMethod === 'mixed') {
      setShowAlertPaymentMixed(true);
      return;
    }
    handlePayment(payMethod, isCustomPay ? toPay : totalSum, '0');
  };
  const handleInputCash = (e: any): void => {
    setToPayCash(e.currentTarget.value);
    mixedBalance(e.currentTarget.value, 'qr');
  };
  const handleInputQR = (e: any): void => {
    setToPayQR(e.currentTarget.value);
    mixedBalance(e.currentTarget.value, 'cash');
  };
  const mixedBalance = (value: string, type: string): void => {
    let isValid: boolean = shiftStore.checkValidCost(value);
    setToPayMixedInvalid(!isValid);
    if (!isValid) return;
    const total: string = isCustomPay ? toPay : totalSum;
    if (parseFloat(total) < parseFloat(value)) {
      setToPayMixedInvalid(true);
      return;
    }
    const _value: string = shiftStore.subtractionFloat(total, value);
    if (type === 'cash') setToPayCash(_value);
    else setToPayQR(_value);
  };
  const handlePayment = (payMethod?: ('cash' | 'qr' | 'mixed'), cash?: string, qr?: string): void => {
    setShowAlertPaymentMixed(false);
    payMethod = payMethod || 'mixed';
    cash = cash || parseFloat(toPayCash).toString();
    qr = qr || parseFloat(toPayQR).toString();
    if (payMethod === 'mixed' && parseFloat(qr) === 0) payMethod = 'cash';
    if (parseFloat(cash) === 0) {
      payMethod = 'qr';
      cash = qr;
      qr = '0';
    }
    const paymentsOutput: ShiftRoutePayment[] = [];
    for (let i = 0; i < payments.length; i++) {
      if (payments[i].checked && !payments[i].paid) paymentsOutput.push(payments[i]);
    }
    if (paymentsOutput.length > 1 || payMethod === 'cash') {
      shiftStore.routePay(routeId, paymentsOutput, payMethod, [cash, qr]).catch(
        () => void 0).finally(() => {
        let success = true;
        for (let i = 0; i < payments.length; i++) {
          if (payments[i].checked && !payments[i].paid) success = false;
        }
        resetPayments();
        if (success) setShowAlertPaymentSuccess(true);
      });
    } else {
      shiftStore.routePay(routeId, paymentsOutput, payMethod, [cash, '0']).then(() => {
        if (!cash || !qr) return;
        shiftStore.routePayQR(routeId, paymentsOutput[0].id, qr !== '0' ? qr : cash).then((e) => {
          if (!e) return;
          setOrderId(paymentsOutput[0].id);
          setCodeQR('data:image/png;base64,' + e.qr);
          setPaymentId(e.payment_id);
          setShowAlertPaymentQR(true);
        }).catch(() => void 0);
      }).catch(() => void 0);
    }
  };
  const calcTotalSum = (): void => {
    let sum: string = '0';
    let requiredSum: string = '0';
    let countChecked = 0;
    for (let i = 0; i < payments.length; i++) {
      if (payments[i].checked && !payments[i].paid) {
        if (!payments[i].postpay) requiredSum = shiftStore.additionFloat(requiredSum, payments[i].cost);
        sum = shiftStore.additionFloat(sum, payments[i].cost);
        countChecked++;
      }
    }
    setIsMultiselect(countChecked > 1);
    setTotalSum(sum);
    setTotalRequiredSum(requiredSum);
    setToPay(sum);
    setToPayInvalid(!shiftStore.checkValidCost(sum));
  };
  const resetPayments = (resetAll = true): void => {
    for (let i = 0; i < payments.length; i++) payments[i].checked = false;
    setTotalSum('0');
    setTotalRequiredSum('0');
    setToPay('0');
    setToPayCash('0');
    setToPayQR('0');
    setToPayInvalid(false);
    setToPayMixedInvalid(false);
    setIsCustomPay(false);
    setShowPaymentList(false);
    setShowAlertPaymentAmount(false);
    setShowAlertPaymentMixed(false);
    setIsMultiselect(false);
    if (resetAll) setShowAlertPaymentSuccess(false);
    setShowAlertPaymentQR(false);
    setPaymentId(null);
    setOrderId(null);
  };

  useEffect(() => {
    if (paymentId === null) return;
    const interval = setInterval(() => {
      if (paymentId === null) return;
      shiftStore.routePayCheckStatus(paymentId).then((e) => {
        if (!e) return;
        if (e.data.status === 'STARTED' || e.data.status === 'CONFIRMED') return;
        if (e.data.status === 'ACCEPTED') {
          for (let i = 0; i < payments.length; i++) {
            if (payments[i].id === orderId) {
              payments[i].paid = true;
              break;
            }
          }
          resetPayments(false);
          setShowAlertPaymentSuccess(true);
          return;
        }
        resetPayments();
        if (e.data.status === 'REJECTED') {
          shiftStore.error.push('Оплата отклонена банком');
          return;
        }
        if (e.data.status === 'REJECTED_BY_USER') {
          shiftStore.error.push('Оплата отклонена покупателем');
          return;
        }
      }).catch((error) => {
        error && console.error(error);
      });
    }, 2000);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [paymentId]);

  return (
    <>
      <IonPopover
        isOpen={showPaymentList}
        onDidDismiss={handleHidePaymentsList}
      >
        <div className="payment-content">
          {payments.map((payment, i) => (
            <div className={`payment-content__item${payment.paid ? ' payment-content__item_paid' : ''}`}
                 key={payment.id || i}>
              <div className="payment-content__head">
                <div>
                  <div className="payment-content__check">
                    <IonCheckbox slot="start"
                                 checked={payment.paid || payment.checked}
                                 disabled={payment.paid || parseFloat(payment.cost.toString()) === 0}
                                 onClick={(e) => handleSelectPayment(payment, e)} />
                  </div>
                  <div className="payment-content__label">
                    {payment.paid ? 'Оплачено' : 'Не оплачено'}
                  </div>
                </div>
                <div className={`payment-content__label payment-content__label_cost${payment.postpay ? '' : ' payment-content__label_required'}`}>
                  {shiftStore.priceFormat(payment.cost)} ₽
                </div>
              </div>
              <div className="payment-content__text">{payment.pay_name}</div>
            </div>
          ))}
          {totalSum !== '0' && (
            <div className="payment-content__item">
              <div className="payment-content__head">
                <div className="payment-content__check">
                  <IonCheckbox slot="start" checked={isCustomPay} onClick={handleCustomPay} />
                </div>
                <IonItem className="start-0">
                  <IonLabel className="code-label" position="floating">Изменить сумму</IonLabel>
                  <IonInput className={toPayInvalid ? '_error' : ''}
                            disabled={!isCustomPay}
                            onInput={handleInputToPay}
                            value={toPay}
                            type="tel"
                            inputMode="tel" />
                </IonItem>
              </div>
            </div>
          )}
        </div>
        <div className="popover-buttons">
          <IonButton fill="clear" color="medium" className="secondary" onClick={handleHidePaymentsList}>
            Отмена
          </IonButton>
          <IonButton fill="clear"
                     disabled={totalSum === '0' || (isCustomPay && toPayInvalid)}
                     onClick={handleShowPaymentAmount}>
            ПЕРЕЙТИ К ОПЛАТЕ
          </IonButton>
        </div>
      </IonPopover>

      <IonAlert
        cssClass="alert-payment-amount"
        isOpen={showAlertPaymentAmount}
        backdropDismiss={false}
        onDidDismiss={handleHidePaymentAmount}
        message={`Сумма к оплате:<br>${isCustomPay ? toPay : totalSum} ₽`}
        inputs={[
          {
            name: 'type',
            type: 'radio',
            label: 'Наличными',
            value: 'cash',
            checked: true,
          },
          {
            name: 'type',
            type: 'radio',
            label: 'QR код',
            disabled: isMultiselect,
            value: 'qr',
          },
          {
            name: 'type',
            type: 'radio',
            label: 'Смешанная',
            disabled: isMultiselect,
            value: 'mixed',
          },
        ]}
        buttons={[
          {
            text: 'НАЗАД',
            cssClass: 'secondary',
            handler: handleShowPaymentsList,
          },
          {
            text: 'ОПЛАТИТЬ',
            handler: handlePaymentAmountNext,
          },
        ]}
      />

      <IonPopover
        isOpen={showAlertPaymentMixed}
        backdropDismiss={false}
        onDidDismiss={handleHidePaymentMixed}
      >
        <div className="payment-mixed">
          <div className="payment-mixed__title">
            Сумма к оплате:
            <br />
            {`${isCustomPay ? toPay : totalSum} ₽`}
          </div>
          <IonItem className="start-0">
            <IonLabel className="code-label" position="floating">Наличными</IonLabel>
            <IonInput className={toPayMixedInvalid ? '_error' : ''}
                      onInput={handleInputCash}
                      value={toPayCash}
                      type="tel"
                      inputMode="tel" />
          </IonItem>
          <IonItem className="start-0">
            <IonLabel className="code-label" position="floating">По QR коду</IonLabel>
            <IonInput className={toPayMixedInvalid ? '_error' : ''}
                      onInput={handleInputQR}
                      value={toPayQR}
                      type="tel"
                      inputMode="tel" />
          </IonItem>
        </div>
        <div className="popover-buttons">
          <IonButton fill="clear" color="medium" className="secondary" onClick={handleShowPaymentAmount}>
            НАЗАД
          </IonButton>
          <IonButton fill="clear" disabled={toPayMixedInvalid} onClick={() => handlePayment()}>
            ОПЛАТИТЬ
          </IonButton>
        </div>
      </IonPopover>

      <IonPopover
        isOpen={showAlertPaymentQR}
        backdropDismiss={false}
      >
        <img src={codeQR} alt="" />
        <div className="popover-buttons">
          <IonButton fill="clear" color="medium" className="secondary" onClick={handleHidePaymentQR}>
            Отмена
          </IonButton>
        </div>
      </IonPopover>

      <IonAlert
        cssClass="alert-payment-success"
        isOpen={showAlertPaymentSuccess}
        onDidDismiss={handleHidePaymentSuccess}
        message={'Успешно!'}
        buttons={['OK']}
      />

      <div className="content-block">
        <IonCardHeader>
          <IonCardSubtitle>Оплата</IonCardSubtitle>
        </IonCardHeader>
        {payments.length === 0 ? (
          <IonItem lines="none">
            <IonLabel className="ion-text-wrap">Заказы на оплату отсутствуют</IonLabel>
          </IonItem>
        ) : (
          <div className="btn-wrap">
            <IonButton className="btn-secondary" expand="block" onClick={handleShowPaymentsList}>
              ПРИНЯТЬ ОПЛАТУ
            </IonButton>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(ShiftRoutePayments);
