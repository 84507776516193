import React from "react";
import {observer} from "mobx-react";
import {IonItem, IonLabel, IonNote} from "@ionic/react";
import {ShiftStore} from "../stores/ShiftStore";

interface Props {
    shiftStore: ShiftStore;
}

const ShiftRoutes: React.FC<Props> = ({shiftStore}) => {
    return (
        <>
            {shiftStore.shiftRoutes.map((route, i) => (
                <div className={route.finished ? "route-finished" : "" || route.old_route ? "route-old" : "" || route.address_fail ? "route-disabled" : ""}
                     key={route.route_id || i}
                >
                    <IonItem lines="none" routerLink={`/shift/routes/${route.route_id}`}>
                        <IonLabel className="ion-text-wrap">
                            {route.legal_name || "Название отсутствует"}
                            {route.accept_payment && <>{" "}<span className="label">Забрать деньги</span></>}
                        </IonLabel>
                    </IonItem>
                    <IonItem routerLink={`/shift/routes/${route.route_id}`}>
                        <IonLabel className="routes-address ion-text-wrap">
                            {route.address_short || route.address || "Адрес отсутствует"}
                        </IonLabel>
                        <IonNote slot="end">{shiftStore.priceFormat(route.total_cost)} ₽</IonNote>
                    </IonItem>
                </div>
            ))}
        </>
    );
};

export default observer(ShiftRoutes);
