import {IonButton, IonCard, IonCardContent, IonCol, IonContent, IonPage, IonRow} from '@ionic/react';
import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import {VERSION} from '../config';
import {AuthStore} from '../stores/AuthStore';
import {RouteComponentProps, useHistory} from "react-router";
import {ShiftStore} from "../stores/ShiftStore";

interface CustomProps extends RouteComponentProps {
    authStore: AuthStore;
    shiftStore: ShiftStore;
}

const Start: React.FC<CustomProps> = ({authStore, shiftStore}) => {
    const history = useHistory();

    useEffect(() => {
        if (!authStore.isAuth) history.replace('/auth');
        if (shiftStore.shiftStart) history.replace('/shift/routes');
    }, [shiftStore.shiftStart, authStore.isAuth, history]);

    const handleStartShift = (): void => shiftStore.startShift(null);
    const handleLogout = (): void => {
        shiftStore.resetShift();
        authStore.logout();
    };

    return (
        <IonPage>
            <IonContent>
                <div className="start-wrap">
                    <div className="start-wrap__info">
                        <div className="start-wrap__version">Версия {VERSION}</div>
                        <div className="start-wrap__text">{shiftStore.welcomeText},</div>
                        <div className="start-wrap__name">{authStore.userName}</div>
                    </div>
                </div>
                <IonCard>
                    <IonCardContent>
                        <div className="btn-wrap btn-wrap_page-start">
                            <IonRow>
                                <IonCol>
                                    <IonButton className="btn-start" expand="block" onClick={handleStartShift}>
                                        НАЧАТЬ СМЕНУ
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton className="btn-secondary" expand="block" onClick={handleLogout}>
                                        ВЫЙТИ
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default inject('authStore', 'shiftStore')(observer(Start));
