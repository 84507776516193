import {CameraResultType, Plugins} from '@capacitor/core';
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCardHeader,
    IonCardSubtitle,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {closeSharp, navigate} from "ionicons/icons";
import {RouteComponentProps, useHistory} from "react-router";
import {inject, observer} from "mobx-react";
import ShiftCollections from '../components/ShiftCollections';
import ShiftTasks from '../components/ShiftTasks';
import {ShiftCollection, ShiftStore} from "../stores/ShiftStore";

interface CustomProps extends RouteComponentProps<{ part: string; }> {
    shiftStore: ShiftStore;
}

const End: React.FC<CustomProps> = ({match, shiftStore}) => {
    const history = useHistory();

    useIonViewWillEnter(() => resetState());
    useIonViewWillLeave(() => resetState());

    useEffect(() => {
        if (!shiftStore.shiftStart) history.replace('/shift/start');
    }, [shiftStore.shiftStart, history]);

    useEffect(() => {
        shiftStore.getShiftInfo(shiftStore.shiftId, null);
    }, [shiftStore]);

    const [showAlertEnd, setShowAlertEnd] = useState<boolean>(false);
    const [showPopoverValue, setShowPopoverValue] = useState<boolean>(false);
    const [collection, setCollection] = useState<ShiftCollection | null>(null);
    const [isInvalidValue, setIsInvalidValue] = useState<boolean>(false);
    const [manualBalance, setManualBalance] = useState<string>('');

    const handleHideAlertEnd = (): void => {
        setShowAlertEnd(false);
        shiftStore.setShiftEnd();
    };
    const handleShiftEnd = (): void => {
        for (let i = 0; i < shiftStore.shiftCollections.length; i++) {
            if (!shiftStore.shiftCollections[i].photo || !shiftStore.shiftCollections[i].manual_balance) {
                setCollection(shiftStore.shiftCollections[i]);
                setManualBalance(shiftStore.collectionBalanceRound(shiftStore.shiftCollections[i].balance_info.balance_ending));
                return setShowPopoverValue(true);
            }
        }
        setShowAlertEnd(true);
        shiftStore.endShift();
    };
    const handleInput = (e: any): void => {
        setManualBalance(e.currentTarget.value);
        setIsInvalidValue(!shiftStore.checkValidCost(e.currentTarget.value));
    };
    const handleSavePopoverValue = (): void => {
        setShowPopoverValue(false);
        if (manualBalance && collection) collection.manual_balance = parseFloat(manualBalance);
        handleShiftEnd();
    };
    const handlePhoto = (): void => {
        const {Camera} = Plugins;
        Camera.getPhoto({
            quality: 50,
            allowEditing: false,
            height: 1000,
            resultType: CameraResultType.Base64
        }).then((e) => {
            if (collection) collection.photo = 'data:image/jpeg;base64,' + e.base64String;
        }).catch(e => console.error(e));
    };
    const resetState = (): void => {
        setShowAlertEnd(false);
        setShowPopoverValue(false);
        setIsInvalidValue(false);
        setCollection(null);
        setManualBalance('');
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton className="btn-close" routerLink="/shift/routes" routerDirection="back">
                            <IonIcon className="icon-close" icon={closeSharp}/>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{match.params.part ? 'Инкассация' : 'Закрыть смену'}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={(e) => shiftStore.getShiftInfo(shiftStore.shiftId, e)}>
                    <IonRefresherContent/>
                </IonRefresher>
                <IonPopover
                    isOpen={showPopoverValue}
                    backdropDismiss={false}
                >
                    <IonItem lines="none" className="start-0 mt-n20">
                        <IonText>
                            <h6>
                                Заполните данные по банковской карте:
                                <br/>
                                {collection && shiftStore.cardNumberFormat(collection.collection_name)}
                            </h6>
                        </IonText>
                    </IonItem>
                    <IonItem className="start-0">
                        <IonLabel className="mt-30" slot="end">₽</IonLabel>
                        <IonLabel className="code-label" position="floating">Введите сумму на чеке</IonLabel>
                        <IonInput className={isInvalidValue ? '_error' : ''}
                                  onInput={handleInput}
                                  value={manualBalance}
                                  type="tel"
                                  inputMode="tel"/>
                    </IonItem>
                    <div className="popover-buttons">
                        <IonButton fill="clear" color="medium" className="secondary" onClick={handlePhoto}>
                            Сфотографировать ЧЕК
                        </IonButton>
                        <IonButton fill="clear"
                                   onClick={handleSavePopoverValue}
                                   disabled={!collection?.photo || isInvalidValue}>
                            OK
                        </IonButton>
                    </div>
                </IonPopover>

                <IonAlert
                    cssClass="alert-end"
                    isOpen={shiftStore.shiftCloseSuccess && showAlertEnd}
                    onDidDismiss={handleHideAlertEnd}
                    message={'Смена закрыта. Всего доброго!'}
                    buttons={['OK']}
                />

                {!match.params.part && <ShiftTasks shiftStore={shiftStore}/>}
                <ShiftCollections shiftStore={shiftStore}/>
                <div className="content-block">
                    <IonCardHeader className="border">
                        <IonCardSubtitle>Действия</IonCardSubtitle>
                    </IonCardHeader>
                    <IonItem lines="none" href="yandexnavi://map_search?text=Альфа-Банк, банкоматы">
                        <IonLabel className="ion-text-wrap">Показать ближайшие банкоматы</IonLabel>
                        <IonIcon className="icon-navigate" slot="start" icon={navigate}/>
                    </IonItem>
                    {!match.params.part && (
                        <div className="btn-wrap pt-22">
                            <IonButton className="btn-primary" expand="block" onClick={handleShiftEnd}>
                                ЗАВЕРШИТЬ СМЕНУ
                            </IonButton>
                        </div>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default inject('shiftStore')(observer(End));
