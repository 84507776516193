import React, {useState} from "react";
import {ShiftRoute, ShiftStore} from "../stores/ShiftStore";
import {observer} from "mobx-react";
import {
    IonAlert,
    IonButton,
    IonCardHeader,
    IonCardSubtitle,
    IonItem,
    IonLabel,
    useIonViewWillEnter,
    useIonViewWillLeave
} from "@ionic/react";
import {useHistory} from "react-router";

interface Props {
    shiftStore: ShiftStore;
    route: ShiftRoute;
}

const ShiftRouteActions: React.FC<Props> = ({shiftStore, route}) => {
    const history = useHistory();
    const [showAlertNotify, setShowAlertNotify] = useState<boolean>(false);
    const [showAlertEnd, setShowAlertEnd] = useState<boolean>(false);
    const [showAlertEndWarning, setShowAlertEndWarning] = useState<boolean>(false);

    useIonViewWillEnter(() => resetState());
    useIonViewWillLeave(() => resetState());

    const resetState = (): void => {
        setShowAlertNotify(false);
        setShowAlertEnd(false);
        setShowAlertEndWarning(false);
    };
    const handleHideAlertNotify = (): void => setShowAlertNotify(false);
    const handleHideAlertEnd = (): void => {
        setShowAlertEnd(false);
        history.push('/shift/routes');
    };
    const handleHideAlertEndWarning = (): void => setShowAlertEndWarning(false);
    const handleRouteStart = (): void => shiftStore.routeStart(route.route_id);
    const handleArrived = (): void => {
        shiftStore.routeArrived(route.route_id);
        setShowAlertNotify(true);
    };
    const handleRouteEnd = (): void => {
        shiftStore.routeEnd(route);
        setShowAlertEnd(true);
    };
    const handleCheckTasks = (): void => {
        if (shiftStore.checkFinishedTasks(route.tasks)) handleRouteEnd();
        else setShowAlertEndWarning(true);
    };

    return (
        <>
            <IonAlert
                cssClass="alert-notify"
                isOpen={shiftStore.shiftRouteState[route.route_id] === 'arrived' && showAlertNotify}
                onDidDismiss={handleHideAlertNotify}
                message={'Клиент уведомлен о вашем прибытии'}
                buttons={['OK']}
            />

            <IonAlert
                cssClass="alert-end"
                isOpen={route.finished && showAlertEnd}
                onDidDismiss={handleHideAlertEnd}
                message={'Маршрут завершен!'}
                buttons={['OK']}
            />

            <IonAlert
                cssClass="alert-end"
                isOpen={showAlertEndWarning}
                onDidDismiss={handleHideAlertEndWarning}
                message={'<div class="color-red">На маршруте остались незавершённые задачи!<br>Всё равно завершить маршрут?</div>'}
                buttons={[
                    {
                        text: 'Да',
                        cssClass: 'secondary',
                        handler: handleRouteEnd
                    },
                    'Нет'
                ]}
            />

            <div className="content-block">
                <IonCardHeader>
                    <IonCardSubtitle>Действия</IonCardSubtitle>
                </IonCardHeader>
                <div className="btn-wrap">
                    {(!shiftStore.shiftRouteState[route.route_id] && !route.finished) && (
                        <IonButton className="btn-secondary" expand="block" onClick={handleRouteStart}>
                            НАЧАТЬ МАРШРУТ
                        </IonButton>
                    )}
                    {shiftStore.shiftRouteState[route.route_id] === 'started' && (
                        <IonButton className="btn-primary" expand="block" onClick={handleArrived}>
                            Я ПРИЕХАЛ
                        </IonButton>
                    )}
                    {shiftStore.shiftRouteState[route.route_id] === 'arrived' && (
                        <IonButton className="btn-primary" expand="block" onClick={handleCheckTasks}>
                            ЗАВЕРШИТЬ МАРШРУТ
                        </IonButton>
                    )}
                </div>
                {route.finished && (
                    <IonItem lines="none">
                        <IonLabel className="ion-text-wrap">Маршрут завершён</IonLabel>
                    </IonItem>
                )}
            </div>
        </>
    );
};

export default observer(ShiftRouteActions);
