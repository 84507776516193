import * as Sentry from '@sentry/browser';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_ROOT, REQUEST_TIMEOUT } from '../config';
import { authStore } from '../stores/AuthStore';
import { Disagreement, PaymentData, ShiftCard, ShiftCollection, ShiftRoute, ShiftTask } from '../stores/ShiftStore';
import { Plugins } from '@capacitor/core';

const { Network } = Plugins;

type HeadersToken = {
    headers: {
        token?: string
    }
}

interface ApiResponse {
    success: boolean;
    message?: string;
    data: { [key: string]: any };
}

interface SendCodeResponse extends ApiResponse {
    data: {
        first_name: string;
        last_name: string;
        token: string;
    }
}

interface StartShiftResponse extends ApiResponse {
    shift_id: number;
    data: {
        date: string,
        routes: ShiftRoute[] | []
    }
}

interface ShiftInfoResponse extends ApiResponse {
    shift_id: number;
    data: {
        tasks: ShiftTask[] | [],
        collection: ShiftCollection[] | [],
        cards: ShiftCard[],
    }
}

interface OrderDisagreementResponse extends ApiResponse {
    total_amount: number;
}

interface DisagreementListResponse extends ApiResponse {
    data: Disagreement[] | [];
}

interface RoutePayQRResponse extends ApiResponse {
    data: {
        qr: string,
        payment_id: number
    };
}

export interface RoutePayCheckStatusResponse extends ApiResponse {
    data: {
        status: 'STARTED' | 'CONFIRMED' | 'REJECTED' | 'REJECTED_BY_USER' | 'ACCEPTED',
    };
}

export interface ErrorResponse {
    status: number;
    statusText: string;
    config: AxiosRequestConfig;
    data: {
        success: boolean;
        message?: string;
        [key: string]: any;
    };
}

interface Requests {
    get(url: string): Promise<any>;

    post(url: string, body?: object): Promise<any>;

    postMedia(url: string, body?: object): Promise<any>;
}

interface AuthRequests {
    sendPhone(data: { phone_number: string }): Promise<any>;

    sendCode(data: { phone_number: string, sms_code: string }): Promise<SendCodeResponse>;
}

interface ShiftRequests {
    start(): Promise<StartShiftResponse>;

    info(data: { shift_id: number }): Promise<ShiftInfoResponse>;

    end(formData: FormData): Promise<any>;

    taskClose(data: { task_id: number }): Promise<any>;

    routeStart(data: { route_id: number }): Promise<any>;

    routeArrived(data: { route_id: number }): Promise<any>;

    routeEnd(data: { route_id: number }): Promise<any>;

    routePay(data: { route_id: number, payments: PaymentData[] }): Promise<any>;

    routePayQR(data: { job_id: number, order_id: number, cost: string }): Promise<RoutePayQRResponse>;

    routePayCheckStatus(paymentId: number): Promise<RoutePayCheckStatusResponse>;

    orderCancel(data: { order_id: number, cause: string }): Promise<any>;

    orderDisagreement(formData: FormData): Promise<OrderDisagreementResponse>;

    disagreementList(): Promise<DisagreementListResponse>;
}

axios.defaults.baseURL = API_ROOT;
axios.defaults.timeout = REQUEST_TIMEOUT;

const responseBody = (response: AxiosResponse) => response.data;
const responseError = (response: AxiosError) => Promise.reject(response.response);
const responseNetworkError: ErrorResponse = {
    status: 12029,
    statusText: 'Cannot Connect',
    config: {},
    data: {
        success: false,
        message: 'Cannot Connect'
    }
};
const responseTimeoutError: ErrorResponse = {
    status: 12002,
    statusText: 'Timeout',
    config: {},
    data: {
        success: false,
        message: 'Timeout'
    }
};

const setToken = (): HeadersToken => {
    if (!authStore.isAuth) return {headers: {}};
    return {headers: {token: authStore.authToken}};
};

const requests: Requests = {
    get: async (url) => {
        const { connected } = await Network.getStatus();
        if (!connected) return Promise.reject(responseNetworkError);
        return axios.get(url, setToken())
          .then(responseBody)
          .catch(responseError);
    },

    post: async (url, body) => {
        const { connected } = await Network.getStatus();
        if (!connected) return Promise.reject(responseNetworkError);
        return axios.post(url, body, setToken())
          .then(responseBody)
          .catch(responseError);
    },

    postMedia: async (url, body) => {
        const { connected } = await Network.getStatus();
        if (!connected) return Promise.reject(responseNetworkError);
        return axios.post(url, body, { headers: { ...setToken().headers, 'Content-Type': 'multipart/form-data' } })
          .then(responseBody)
          .catch(responseError);
    }
};

export const AuthRequests: AuthRequests = {
    sendPhone: (data) =>
        requests.post('auth/auth', data),
    sendCode: (data) =>
        requests.post('auth/code', data)
};

export const ShiftRequests: ShiftRequests = {
    start: () =>
      requests.post('forwarder/work_shift/start'),
    info: (data) =>
      requests.post('forwarder/work_shift/info', data),
    end: (formData) =>
      requests.postMedia('forwarder/work_shift/end', formData),
    taskClose: (data) =>
      requests.post('forwarder/task/close', data),
    routeStart: (data) =>
      requests.post('forwarder/route/start', data),
    routeArrived: (data) =>
      requests.post('forwarder/route/arrived', data),
    routeEnd: (data) =>
      requests.post('forwarder/route/end', data),
    routePay: (data) =>
      requests.post('forwarder/route/pay/cash', data),
    routePayQR: (data) =>
      requests.post('forwarder/route/pay/qr', data),
    routePayCheckStatus: (paymentId) =>
      requests.get('forwarder/route/pay/qr/status?payment_id=' + paymentId),
    orderCancel: (data) =>
      requests.post('forwarder/order/cancel', data),
    orderDisagreement: (formData) =>
      requests.postMedia('forwarder/order/disagreement', formData),
    disagreementList: () =>
      requests.get('disagreement/list'),
};

const parseRequest = (request?: any): string => {
    if (request instanceof FormData) {
        let tempData: { [key: string]: any } = {};
        request.forEach((value, key) => {
            if (value instanceof File) value = 'binary';
            tempData[key] = value;
        });
        return JSON.stringify(tempData);
    }
    return JSON.stringify(request);
}

axios.interceptors.response.use(function (response) {
    Sentry.withScope(function (scope) {
        scope.setExtras({
            request: {
                url: `${response.config.baseURL}${response.config.url}`,
                method: response.config.method,
                data: parseRequest(response.config.data)
            },
            response: JSON.stringify(response.data),
            status: response.status
        });
        Sentry.captureMessage(
            `[${response.status}] ${response.config.baseURL}${response.config.url}`,
            Sentry.Severity.Info
        );
    });
    return response;
}, function (error) {
    if (!error.response) {
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            error.response = responseTimeoutError;
            error.response.config = error.config;
        } else {
            error.response = {
                status: error.code,
                statusText: '',
                config: error.config,
                headers: null,
                data: {
                    success: false,
                    message: error.message || ''
                }
            }
        }
    }
    Sentry.withScope(function (scope) {
        scope.setExtras({
            request: {
                url: error.response.config.baseURL + error.response.config.url,
                method: error.response.config.method,
                data: parseRequest(error.response.config.data)
            },
            response: JSON.stringify(error.response.data),
            status: error.response.status
        });
        Sentry.captureMessage(
            `[${error.response.status}] ${error.response.config.baseURL}${error.response.config.url}`,
            Sentry.Severity.Error
        );
    });
    return Promise.reject(error);
});
