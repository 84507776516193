import {IonButton, IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle} from '@ionic/react';
import React from 'react';
import {VERSION} from '../config';
import {AuthStore} from "../stores/AuthStore";
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {ShiftStore} from '../stores/ShiftStore';

interface CustomProps extends RouteComponentProps {
    authStore: AuthStore;
    shiftStore: ShiftStore;
}

interface AppPage {
    readonly url: string;
    readonly title: string;
    readonly name: string;
    readonly external?: boolean;
}

const appPages: AppPage[] = [
    {
        title: 'Маршрутный лист',
        name: 'routes',
        url: '/shift/routes'
    },
    {
        title: 'Навигатор',
        name: 'navigator',
        url: 'yandexnavi://',
        external: true
    },
    {
        title: 'Инкассация',
        name: 'collection',
        url: '/shift/end/collection'
    },
    {
        title: 'Завершить смену',
        name: 'end',
        url: '/shift/end'
    }
];

const Menu: React.FC<CustomProps> = ({authStore, shiftStore}) => {
    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <div className="menu-head">
                    <div className="menu-head__info">
                        <IonButton className="btn-secondary btn-update" onClick={() => shiftStore.updateApp()}>Обновить приложение</IonButton>
                        <div className="menu-head__version">Версия {VERSION}</div>
                        <div className="menu-head__name">{authStore.userName}</div>
                        <div className="menu-head__phone">{authStore.prettyPhone}</div>
                    </div>
                </div>
                <IonList id="inbox-list">
                    {appPages.map((appPage, i) => {
                        return (
                            <IonMenuToggle key={i} autoHide={false}>
                                {appPage.external ? (
                                    <IonItem href={appPage.url} lines="none" detail={false}>
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                ) : (
                                    <IonItem routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                )}
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default inject('authStore')(observer(Menu));
