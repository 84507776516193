import {
    IonButtons,
    IonCardHeader,
    IonCardSubtitle,
    IonContent,
    IonHeader,
    IonList,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useEffect} from 'react';
import {RouteComponentProps, useHistory} from "react-router";
import {inject, observer} from "mobx-react";
import {ShiftStore} from "../stores/ShiftStore";
import ShiftRoutes from "../components/ShiftRoutes";

interface CustomProps extends RouteComponentProps {
    shiftStore: ShiftStore;
}

const Routes: React.FC<CustomProps> = ({shiftStore}) => {
    const history = useHistory();

    useEffect(() => {
        if (!shiftStore.shiftStart) history.replace('/shift/start');
    }, [shiftStore.shiftStart, history]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton id="btn-show-menu"/>
                    </IonButtons>
                    <IonTitle>Маршрутный лист</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={e => shiftStore.startShift(e)}>
                    <IonRefresherContent/>
                </IonRefresher>
                <div className="content-block">
                    <IonCardHeader>
                        <IonCardSubtitle>{shiftStore.shiftDateFormat}</IonCardSubtitle>
                    </IonCardHeader>
                    {shiftStore.shiftRoutes.length !== 0 ? (
                        <IonList>
                            <ShiftRoutes shiftStore={shiftStore}/>
                        </IonList>
                    ) : <div className="btn-wrap">Маршрутный лист пуст</div>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default inject('shiftStore')(observer(Routes));
