import * as Sentry from '@sentry/browser';
import React, {useEffect, useState} from "react";
import {ShiftRouteContacts, ShiftStore} from "../stores/ShiftStore";
import {observer} from "mobx-react";
import {
    ActionSheetButton,
    IonActionSheet,
    IonButton,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonRow,
    useIonViewWillEnter,
    useIonViewWillLeave
} from "@ionic/react";
import {CallNumber} from "@ionic-native/call-number";

interface Props {
    shiftStore: ShiftStore;
    contacts: ShiftRouteContacts;
}

interface ConnectControls {
    client: ActionSheetButton[];
    manager: ActionSheetButton[];
}

const ShiftRouteConnect: React.FC<Props> = ({shiftStore, contacts}) => {
    useIonViewWillEnter(() => resetState());
    useIonViewWillLeave(() => resetState());

    const resetState = (): void => {
        setShowConnectClient(false);
        setShowConnectManager(false);
    };
    const [connectControls, setConnectControls] = useState<ConnectControls>({client: [], manager: []});
    const [showConnectClient, setShowConnectClient] = useState<boolean>(false);
    const [showConnectManager, setShowConnectManager] = useState<boolean>(false);
    const handleShowSheetClient = (): void => setShowConnectClient(true);
    const handleHideSheetClient = (): void => setShowConnectClient(false);
    const handleShowSheetManager = (): void => setShowConnectManager(true);
    const handleHideSheetManager = (): void => setShowConnectManager(false);

    useEffect(() => {
        const connectControls: ConnectControls = {
            client: [],
            manager: []
        };
        const contactTypes: ('client' | 'manager')[] = ['client', 'manager'];
        for (let i = 0; i < contactTypes.length; i++) {
            if (contacts[contactTypes[i]].phone) {
                const phone: string = shiftStore.cleanPhoneNumber(contacts[contactTypes[i]].phone!);
                connectControls[contactTypes[i]].push({
                    text: 'Позвонить',
                    handler: () => {
                        CallNumber.callNumber(phone, true).then(e => {
                            console.error(e);
                            Sentry.addBreadcrumb({
                                category: 'event',
                                message: `Calling to ${phone}`,
                                level: Sentry.Severity.Info
                            });
                        }).catch(e => {
                            console.error(e);
                            Sentry.captureMessage('CallNumberError', Sentry.Severity.Error);
                        });
                    }
                });
            }
            if (contacts[contactTypes[i]].whatsapp) {
                const phone: string = shiftStore.cleanPhoneNumber(contacts[contactTypes[i]].whatsapp!);
                connectControls[contactTypes[i]].push({
                    text: 'Написать WhatsApp',
                    handler: () => {
                        window.location.href = `whatsapp://send/?phone=${phone.substr(1)}`;
                    }
                });
            }
        }
        setConnectControls(connectControls);
    }, [shiftStore, contacts]);

    return (
        <>
            {(connectControls.client.length !== 0 || connectControls.manager.length !== 0) && (
                <>
                    <IonActionSheet
                        isOpen={showConnectClient}
                        onDidDismiss={handleHideSheetClient}
                        header="Связаться с клиентом"
                        buttons={connectControls.client}
                    />

                    <IonActionSheet
                        isOpen={showConnectManager}
                        onDidDismiss={handleHideSheetManager}
                        header="Связаться с менеджером"
                        buttons={connectControls.manager}
                    />

                    <div className="content-block">
                        <IonCardHeader>
                            <IonCardSubtitle>Связаться</IonCardSubtitle>
                        </IonCardHeader>
                        <div className="btn-wrap">
                            <IonRow>
                                {connectControls.client.length !== 0 && (
                                    <IonCol>
                                        <IonButton className="btn-green"
                                                   expand="block"
                                                   onClick={handleShowSheetClient}
                                                   id="btn-connect-client">
                                            С КЛИЕНТОМ
                                        </IonButton>
                                    </IonCol>
                                )}
                                {connectControls.manager.length !== 0 && (
                                    <IonCol>
                                        <IonButton className="btn-primary"
                                                   expand="block"
                                                   onClick={handleShowSheetManager}
                                                   id="btn-connect-manager">
                                            С МЕНЕДЖЕРОМ
                                        </IonButton>
                                    </IonCol>
                                )}
                            </IonRow>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default observer(ShiftRouteConnect);
