import React, {useEffect, useRef, useState} from "react";
import {AuthStore} from "../stores/AuthStore";
import {observer} from "mobx-react";
import {SMS_CODE_DURATION} from "../config";

interface Props {
    authStore: AuthStore;
}

const Timer: React.FC<Props> = ({authStore}) => {
    const [secondsLeft, setSecondsLeft] = useState<number>(SMS_CODE_DURATION);
    const secondsLeftRef = useRef(secondsLeft);
    secondsLeftRef.current = secondsLeft;

    useEffect(() => {
        const interval: any = setInterval(() => {
            if (authStore.isSync) return;
            setSecondsLeft(secondsLeftRef.current - 1);
            if (secondsLeftRef.current <= 0) {
                clearInterval(interval);
                authStore.setGetCode(false);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [authStore]);

    return (<div className="code-timer" slot="end">{secondsLeft} сек.</div>);
};

export default observer(Timer);
