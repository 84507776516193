import {IonToast} from '@ionic/react';
import React from "react";
import {authStore} from "../stores/AuthStore";
import {observer} from "mobx-react";
import {ShiftStore} from '../stores/ShiftStore';

interface Props {
    shiftStore: ShiftStore;
}

const Errors: React.FC<Props> = ({shiftStore}) => {
    const handleToastDismiss = (): void => {
        if (shiftStore.authError !== null) {
            shiftStore.resetShift();
            authStore.logout();
        } else shiftStore.error.pop();
    };

    const handleToastDismissUpdate = (): void => {
        shiftStore.updateAvailable = false;
    };

    return (
        <>
            {shiftStore.error.map((item, i) => (
                <IonToast
                    key={i}
                    isOpen={i === shiftStore.error.length - 1}
                    message={item}
                    cssClass="toast-error"
                    buttons={[
                        {
                            text: 'ОК',
                            role: 'cancel',
                            handler: handleToastDismiss
                        }
                    ]}
                />
            ))}

            <IonToast
                isOpen={shiftStore.authError !== null}
                message={shiftStore.authError!}
                cssClass="toast-error toast-error_autherror"
                buttons={[
                    {
                        text: 'ОК',
                        role: 'cancel',
                        handler: handleToastDismiss
                    }
                ]}
            />

            <IonToast
                isOpen={shiftStore.updateAvailable}
                message="Доступна новая версия приложения"
                cssClass="toast-update"
                buttons={[
                    {
                        text: 'ОК',
                        role: 'cancel',
                        handler: handleToastDismissUpdate
                    }
                ]}
            />
        </>
    );
};

export default observer(Errors);
